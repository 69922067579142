import React,{useState,useEffect} from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row, Table
} from "reactstrap";
import API from "../../utils/API";
import Loader from "../../components/Loader";
import { useHistory } from "react-router-dom";
import AdminStatus from "../../components/status/AdminStatus";
import DateRange from "../../components/formfields/DateRange";
import dateFormat from "dateformat";
import UserStatus from "../../components/formfields/UserStatus";
import ProductSelect from "../../components/formfields/ProductSelect";
import Pagination from "../../components/Pagination";
import ListStat from "../../components/ListStat";

const Users = () => {
    let history = useHistory()
    const defaultDate = dateFormat((new Date()).setDate((new Date()).getDate() - 730),"mmm d, yyyy")+" to "+dateFormat(new Date(),"mmm d, yyyy")

    const [filtration,setFiltration] = useState({page:1,perPage:100,date:defaultDate})
    const [users, setUsers] = useState(null)
    const [loading, setLoading] = useState(true)
    const [data,setData] = useState(null)

    const changeData = (name,value) => {
        setFiltration({ ...filtration, [name]: value })
    }

    useEffect(() => {
        setLoading(true)
        API.post('/admin/users/user',filtration).then((response) => {
            setUsers(response?.data?.data?.data)
            setData(response.data.data)
            setLoading(false)
        });
    }, [filtration])

    const editUser = (userId) => {
        history.push("/user/item/"+userId);
    }

    return (
        <Container fluid className="p-0">
            <div className="row">
                <div className="col">
                    <h1 className="h3 mb-3">Users</h1>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary" onClick={()=>history.push("/user/form")}>+ New</button>
                </div>
            </div>
            <div className="row mt-1 mb-2">
                <div className="col-3">
                    <label>Registration date</label>
                    <DateRange
                        onChange={changeData}
                        name={'date'}
                        value={defaultDate}
                    />
                </div>
                <div className="col-3">
                    <label>User status</label>
                    <UserStatus
                        onChange={changeData}
                        name={'status'}
                    />
                </div>
                <div className="col-3">
                    <label>Subscription</label>
                    <ProductSelect
                        onChange={changeData}
                        name={'product'}
                        required={false}
                    />
                </div>
                <div className="col-3">
                    <label>Keyword</label>
                    <div><input type="text" className="form-control" onChange={(e)=>changeData('keyword',e.target.value)}/></div>
                </div>
            </div>
            <Row>
                <Col>
                    <Card>
                        {loading &&
                            <CardBody><Loader/></CardBody>
                        }
                        {(!loading && users && users.length > 0) ?
                            <div className="table-responsive">
                                <ListStat data={data}/>
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>Registration date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        users.map((user, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><a onClick={() => {editUser(user.id)}} className="text-primary cursor-pointer">{user.name}</a></td>
                                                    <td>{user.email}</td>
                                                    <td><AdminStatus status={user.status}/></td>
                                                    <td>{user.dateadd}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </div>
                            :
                            !loading &&
                            <CardBody className="text-center"><h5>Data not found</h5></CardBody>
                        }
                    </Card>
                    {(data?.page > 0 && data?.pages > 0) &&
                        <Pagination
                            onChange={changeData}
                            name={"page"}
                            page={data.page}
                            totalPages={data.pages}
                            loading={loading}/>
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default Users;
