import React,{useState,useEffect} from "react";
import {useHistory} from "react-router-dom";
import API from "../../utils/API";
import {toastr} from "react-redux-toastr";
import {Button, Card, CardBody, Container, FormGroup, Label} from "reactstrap";
import {AvForm, AvInput} from "availity-reactstrap-validation";
import ImageUploader from "react-images-upload";
import TemplateCategory from "../../components/formfields/TemplateCategory";

const EditTemplate = (props) => {
    const [template, setTemplate] = useState({title:''});
    const [error, setError] = useState(null)
    const [imagesError,setImagesError] = useState(null)

    const changeData = (name,value) => {
        setTemplate({ ...template, [name]: value })
    }

    let history = useHistory();

    const handleChange = (e) => {
        setTemplate({ ...template, [e.target.name]: e.target.value })
        setError(null)
    };

    const upload = (files,pictures) => {
        setTemplate({ ...template, ['logo']: pictures[0] })
        setImagesError(false)
    }

    const onDrop = (picture,pictureURI) => {
        if (pictureURI.length > 0) {
            API.post('/image', {image: pictureURI[0]}).then((response) => {
                setTemplate({ ...template, ['logo']: response.data.data.url })
            });
        }else {
            setTemplate({ ...template, ['logo']: null })
        }
    };

    const submit = () => {
        if (props.match.params.id) {
            API.put('/admin/apptemplate/'+props.match.params.id,template)
                .then((response) => {
                    toastr.success('Success', 'Template was successfully saved')
                    history.push("/templates/list")
                })
                .catch(error => {
                    setError('Invalid template data')
                })
        }
    }

    useEffect(()=>{
        if (props.match.params.id) {
            API.get('/admin/apptemplate/item/'+props.match.params.id)
                .then((response) => {
                    setTemplate(response.data.data)
                })
        }
    },[props.match.params.id])

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Edit template</h1>
            <Card>
                <CardBody>
                    {error &&
                        <div className="text-center text-danger">{error}</div>
                    }
                    <div className="row">
                        <div className="col-12">
                            <AvForm onValidSubmit={submit}>
                                <FormGroup>
                                    <Label>Title</Label>
                                    <AvInput onChange={handleChange} type="text" name="title" value={template.title} placeholder="Title" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Category</Label>
                                    <TemplateCategory onChange={changeData} value={template.category} name={'category'}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Status</Label>
                                    <select className="form-control" value={template.status} onChange={(e)=>changeData('status',e.target.value)}>
                                        <option>Select...</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Keywords</Label>
                                    <AvInput onChange={handleChange} type="textarea" rows={3} name="description" value={template.description} placeholder="Keywords" />
                                </FormGroup>
                                <ImageUploader
                                    withIcon={false}
                                    withPreview={true}
                                    buttonText="Choose images"
                                    onChange={onDrop}
                                    imgExtension={[".jpg",".jpeg",".gif", ".png", ".gif"]}
                                    defaultImages={template.logo?[template.logo]:[]}
                                    singleImage
                                />
                                {imagesError &&
                                    <span className="error text-danger">{imagesError}</span>
                                }
                                <div className="text-center">
                                    <Button color="primary">Save</Button>
                                </div>
                            </AvForm>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Container>
    )
}

export default EditTemplate
