import React,{useState,useEffect} from "react";
import {login} from "../../redux/actions/userActions";
import {Link, Redirect} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    Button,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import API from "../../utils/API";

const Registration = (props) => {
    const [password,setPassword] = useState(null)
    const [confirmpassword,setConfirmpassword] = useState(null)
    const [name,setName] = useState(null)
    const [error,setError] = useState(null)

    const dispatch = useDispatch();

    const sign = useSelector(state => {
        return state.user.sign
    });

    useEffect(() => {
        API.get('/user/registration/'+props.match.params.id).then((response) => {
            setName(response.data.data.name)
        }).catch(error => {
            setError('Invalid invitation')
        })
    }, [])

    const submit = () => {

        if (!password || password.length < 8) {
            setError('Password length should be minimum 8 characters')
            return;
        }

        if (!name || name.length < 2) {
            setError('Invalid name')
            return;
        }

        if (password !== confirmpassword) {
            setError('Password and confirm password did not match')
            return;
        }

        API.post('/user/register/'+props.match.params.id,{password:password,name:name})
            .then(data => {
                dispatch(login(data.data.data));
            })
            .catch(error => {
                setError('Invalid invitation')
            })
    }

    return (
        sign ?
            <Redirect to="/"/>
            :
            <React.Fragment>
                <div className="text-center mt-4">
                    <h1 className="h2">Registration</h1>
                </div>
                <Card>
                    <CardBody>
                        <div className="m-sm-4">
                            {error &&
                            <div className="text-center text-danger">{error}</div>
                            }
                            <Form>
                                <FormGroup>
                                    <Label>Name</Label>
                                    <Input
                                        bsSize="lg"
                                        type="text"
                                        name="name"
                                        value={name}
                                        placeholder="Enter your name"
                                        onChange={(e) => {setName(`${e.target.value}`);setError(null)}}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Password</Label>
                                    <Input
                                        bsSize="lg"
                                        type="password"
                                        name="password"
                                        placeholder="Enter password"
                                        onChange={(e) => {setPassword(`${e.target.value}`);setError(null)}}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Confirm password</Label>
                                    <Input
                                        bsSize="lg"
                                        type="password"
                                        name="password"
                                        placeholder="Confirm password"
                                        onChange={(e) => {setConfirmpassword(`${e.target.value}`);setError(null)}}
                                    />
                                </FormGroup>
                                <div className="text-center mt-3">
                                    <Button color="primary" size="lg" onClick={submit}>
                                        Save
                                    </Button>
                                </div>
                            </Form>
                            <div className="text-center mt-2">
                                <small>
                                    <Link to="/auth/sign-in">Back to Sign In</Link>
                                </small>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
    )
}

export default Registration;
