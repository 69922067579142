import React,{useState,useEffect} from "react";
import {useHistory} from "react-router-dom";
import API from "../../utils/API";
import {toastr} from "react-redux-toastr";
import {Button, Card, CardBody, Container, FormGroup, Label} from "reactstrap";
import {AvField, AvForm, AvInput} from "availity-reactstrap-validation";
import Blockcategory from "../../components/formfields/Blockcategory";
import ImageUploader from "react-images-upload";
import blockFactory from "../builder/blockFactory";

const BlockEdit = (props) => {
    const [block, setBlock] = useState({title:null,priority:1,slug:null,defaultdata:null});
    const [error, setError] = useState(null)
    const [imagesError,setImagesError] = useState(null)
    const [loading,setLoading] = useState(false)

    let history = useHistory();

    const handleChange = (e) => {
        setBlock({ ...block, [e.target.name]: e.target.value })
        setError(null)
    };

    const upload = (files,pictures) => {
        setBlock({ ...block, ['image']: pictures[0] })
        setImagesError(false)
    }

    const submit = () => {
        if (props.match.params.id) {
            API.post('/admin/block/save/'+props.match.params.id,block)
                .then((response) => {
                    toastr.success('Success', 'Block was successfully saved')
                    history.push("/blocks/list")
                })
                .catch(error => {
                    setError('Invalid module data')
                })
        }else {
            API.post('/admin/block/add',block)
                .then((response) => {
                    toastr.success('Success', 'Module was successfully saved')
                    history.push("/blocks/list")
                })
                .catch(error => {
                    setError('Invalid module data')
                })

        }
    }

    useEffect(()=>{
        if (props.match.params.id) {
            API.get('/admin/block/item/'+props.match.params.id)
                .then((response) => {
                    setBlock(response.data)
                })
        }
    },props.match.params.id)

    const setDataValue = (data) => {
        let tempData = block
        block.defaultdata = data
        setBlock(tempData)
    }

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">{props.match.params.id?'Edit':'New'} Module</h1>
            <Card>
                <CardBody>
                    {error &&
                    <div className="text-center text-danger">{error}</div>
                    }
                    <div className="row">
                        <div className="col-12">
                            <AvForm onValidSubmit={submit}>
                                <FormGroup>
                                    <Label>Title</Label>
                                    <AvInput onChange={handleChange} required type="text" name="title" value={block.title} placeholder="Block title" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Category</Label>
                                    <Blockcategory value={block.category} onChange={handleChange} required={true} name={'category'}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Priority</Label>
                                    <AvField
                                        type="select"
                                        name="priority"
                                        value={block.priority}
                                        onChange={handleChange}
                                    >
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                        <option value={10}>10</option>
                                        <option value={11}>11</option>
                                        <option value={12}>12</option>
                                        <option value={13}>13</option>
                                        <option value={14}>14</option>
                                        <option value={15}>15</option>
                                        <option value={16}>16</option>
                                        <option value={17}>17</option>
                                        <option value={18}>18</option>
                                        <option value={19}>19</option>
                                        <option value={20}>20</option>
                                    </AvField>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Title</Label>
                                    <AvInput onChange={handleChange} type="text" name="slug" value={block.slug} placeholder="App block id" />
                                </FormGroup>
                                <ImageUploader
                                    withIcon={false}
                                    withPreview={true}
                                    buttonText="Choose images"
                                    onChange={upload}
                                    imgExtension={[".jpg",".jpeg",".gif", ".png", ".gif"]}
                                    defaultImages={block.image?[block.image]:[]}
                                    singleImage
                                />
                                {imagesError &&
                                <span className="error text-danger">{imagesError}</span>
                                }
                                <div className="text-center">
                                    <Button color="primary">Save</Button>
                                </div>
                            </AvForm>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Container>
    )
}

export default BlockEdit
