import React,{useEffect,useState,useRef} from "react"
import API from "../../../../utils/API";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";

const SlideshowItem = (props) => {
    const [data,setData] = useState(props.data.data)
    const [id,setId] = useState(props.data.id)

    const setValue = (name,value) => {
        let newData = data
        if (Array.isArray(newData)) {
            newData = {}
        }

        newData[name] = value

        setData(newData)

        props.setImageData(id,newData)
    }

    const handleFileSelect = (event) => {
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = (e) => {
            API.post('/imageadmin',{image:e.target.result}).then((response) => {
                setValue('image',response.data.data.url)
            });
        }
    }

    return (<div className="m-1">
        <div className="row">
            <div className="col">
                {data.image &&
                <img src={data.image} className="img-fluid"/>
                }
            </div>
            <div className="col-auto">
                <label>
                    <FontAwesomeIcon icon={faEdit} color={"white"} className="cursor-pointer"/>
                    <input type="file" onChange={handleFileSelect} style={{display:"none"}}/>
                </label>
                <div className="mt-3">
                    <FontAwesomeIcon icon={faTrash} color={"red"} onClick={()=>props.deleteImage(id)} className="cursor-pointer"/>
                </div>
            </div>
        </div>

        <div className={"col-4"}>

        </div>
    </div>)
}

export default SlideshowItem
