import React,{useState,useEffect} from "react";
import {useHistory} from "react-router-dom";
import API from "../../utils/API";
import {Button, Card, CardBody, Col, Container, Row, Table} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Edit2,Trash} from "react-feather";

const Faqs = (props) => {
    const [blocks, setBlocks] = useState(null)
    let history = useHistory();

    const loadBlocks = () => {
        API.get('/admin/faq/all').then((response) => {
            setBlocks(response.data.data)
        });
    }

    useEffect(() => {
        loadBlocks();
    }, [])

    const newCategory = () => {
        history.push("/settings/faqs/item")
    }

    const editCategory = (id) => {
        history.push("/settings/faqs/item/"+id);
    }

    const deleteCategory = (id) => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            API.delete('/admin/faq/item/' + id).then((response) => {
                loadBlocks();
            });
        }
    }

    return (
        <Container fluid className="p-0">
            <Button color="primary" onClick={newCategory} className="float-right mt-n1">
                <FontAwesomeIcon icon={faPlus}/> New faq
            </Button>
            <h1 className="h3 mb-3">Faq</h1>
            <Row>
                <Col>
                    <Card>
                        {(blocks && blocks.length > 0) &&
                            <div className="table-responsive">
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Question</th>
                                        <th>Status</th>
                                        <th>Category</th>
                                        <th>Date add/update</th>
                                        <th>Edit</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        blocks.map((block, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{block.title}</td>
                                                    <td>{block.status === "1"?
                                                    <span className="badge badge-success">Active</span>
                                                        :
                                                        <span className="badge badge-danger">Inactive</span>
                                                    }</td>
                                                    <td>{block.category}</td>
                                                    <td>{block.dateadd}<br/>{block.dateupdate}</td>
                                                    <td className="table-action">
                                                        <Edit2 onClick={() => {editCategory(block.id)}} className="align-middle mr-1 cursor-pointer text-primary" size={18} />
                                                        <Trash onClick={() => {deleteCategory(block.id)}} className="align-middle mr-1 cursor-pointer text-danger" size={18} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Faqs
