import React,{useState} from "react"

const Grid = ({items}) => {
    const [currentItems, setCurrentItems] = useState(items)

    return (
        <div>
            {currentItems.length > 0 &&
                <div className="text-black-50">
                    <div className="row m-2 justify-content-center">
                        {
                            currentItems.map((inCategoryItem,inCategoryItemsKey)=>{
                                return (
                                    <div className="col-sm-6 text-center p-2" key={inCategoryItemsKey}>
                                        {inCategoryItem.image &&
                                            <img className="img-fluid" src={inCategoryItem.image}/>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default Grid
