import React,{useEffect,useState,useRef} from "react"
import API from "../../../../utils/API";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";

const PhotogalleryItem = (props) => {
    const [data,setData] = useState(props.data)

    const setValue = (name,value) => {
        let newData = data
        if (Array.isArray(newData)) {
            newData = {}
        }

        newData[name] = value

        setData(newData)

        props.setImageData(newData)
    }

    const handleFileSelect = (event) => {
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = (e) => {
            API.post('/imageadmin',{image:e.target.result}).then((response) => {
                setValue('src',response.data.data.url)
                setValue('width',response.data.data.width)
                setValue('height',response.data.data.height)
            });
        }
    }

    return (<div className="m-1">
        <div className="row">
            <div className="col">
                {data.src &&
                    <img src={data.src} className="img-fluid"/>
                }
                <div className="mt-2">
                    <input type="text" placeholder="Title" className="form-control" value={data.title} onChange={(e)=>setValue('title',e.target.value)}/>
                </div>
            </div>
            <div className="col-auto">
                <label>
                    <FontAwesomeIcon icon={faEdit} color={"white"} className="cursor-pointer"/>
                    <input type="file" onChange={handleFileSelect} style={{display:"none"}}/>
                </label>
                <div className="mt-3">
                    <FontAwesomeIcon icon={faTrash} color={"red"} onClick={()=>props.deleteImage(props.data.id)} className="cursor-pointer"/>
                </div>
            </div>
        </div>
    </div>)
}

export default PhotogalleryItem
