import React,{useState} from "react";
import {
    Button, Card,
    CardBody,
    Container,
    FormGroup,
    Label,
} from "reactstrap";
import {
    AvField,
    AvForm,
    AvInput
} from "availity-reactstrap-validation";
import API from "../../utils/API";
import { useHistory } from "react-router-dom";
import {toastr} from "react-redux-toastr";

const Admin = (props) => {
    const [user, setUser] = useState({name:null,email:null,status:null});
    const [error, setError] = useState(null)

    let history = useHistory();

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
        setError(null)
    };

    const submit = () => {
        if (props.match.params.id) {

        }else {
            API.post('/admin/users/add',{name:user.name,email:user.email})
                .then((response) => {
                    toastr.success('Success', 'Invitation was successfully sent')
                    history.push("/user/admins")
                })
                .catch(error => {
                    setError('User data')
                })

        }
    }

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">{props.match.params.id?'Edit':'New'} Admin</h1>
            <Card>
                <CardBody>
                    {error &&
                    <div className="text-center text-danger">{error}</div>
                    }
                    <AvForm onValidSubmit={submit}>
                        <FormGroup>
                            <Label>Name</Label>
                            <AvInput onChange={handleChange} required type="text" name="name" value={user.name} placeholder="User full name" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Email</Label>
                            <AvInput onChange={handleChange} required type="email" name="email" value={user.email} placeholder="User email" />
                        </FormGroup>
                        {props.match.params.id &&
                        <FormGroup>
                            <Label>Status</Label>
                            <AvField
                                type="select"
                                name="status"
                                value={user.status}
                                onChange={handleChange}
                            >
                                <option value={1}>Active</option>
                                <option value={0}>Not active</option>
                            </AvField>
                        </FormGroup>
                        }
                        <div className="text-center">
                            <Button color="primary">{props.match.params.id?'Save':'Send invitation'}</Button>
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        </Container>
    )
};

export default Admin;
