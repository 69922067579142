import React,{useEffect,useState,useRef} from "react"
import {Button} from "reactstrap"
import bgImage from "../../../../assets/blocks/header1/bg.jpeg"
import InlineEditableText from "../InlineEditableText"
import Switch from "react-switch"
import API from "../../../../utils/API"
import InputColor from 'react-input-color'
import Slider from 'react-input-slider'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faSave, faEnvelope, faTrash} from "@fortawesome/free-solid-svg-icons";
import { faFacebook,faTwitter,faVk,faOdnoklassniki,faPinterest } from "@fortawesome/free-brands-svg-icons"

const Social = (props) => {

    const [showOption, setShowOptions] = useState(false)
    const [data, setData] = useState((props.data)?props.data:props.defaultdata?props.defaultdata:{size:1,bgColor:"#ffffff",facebookActive:true,facebook:'https://facebook.com',twitterActive:true,twitter:'https://twitter.com',vkActive:true,vk:'https://vk.com',odnoklassniki:'https://odnoklassniki.ru',odnoklassnikiActive:true,pinterest:'https://pinterest.com',pinterestActive:true,email:"mail@mail.com",emailActive:true,titleActive:true,subtitleActive:true,facebookColor:"#4267B2",twitterColor:"#1DA1F2",vkColor:"#4C75A3",odnoklassnikiColor:"#f97400",pinterestColor:"#E60023",emailColor:"#006ee6",nameActive:true,rounded:true})

    const [clickedOutside, setClickedOutside] = useState(false);

    const ref = useRef();

    const handleClickOutside = e => {
        if (ref && ref.current && !ref.current.contains(e.target)) {
            setClickedOutside(true)
            setShowOptions(false)
        }
    };

    const handleClickInside = () => {
        setClickedOutside(false)
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    },[]);

    const setValue = (name,value) => {
        let newData = {
            ...data,
            [name]: value
        }

        setData(newData)
        props.setValue(newData)
    }

    let image = {
        backgroundColor: data.bgColor?data.bgColor:'#ffffff',
        position:"relative",
        border:(props.isResult)?"dashed 1px #64afb9":"0",
        paddingTop: (data.paddingTop || data.paddingTop === 0)?data.paddingTop:0,
        paddingBottom: (data.paddingBottom || data.paddingBottom === 0)?data.paddingBottom:0,
    }

    const handleFileSelect = (event) => {
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = (e) => {
            API.post('/imageadmin',{image:e.target.result}).then((response) => {
                setValue('bgImage',response.data.data.url)
            });
        }
    }

    return (
        <div style={{position:"relative"}} onClick={(e)=>{if (e.target.tagName.toLowerCase() === 'div'){
            if (props.isResult) {
                setShowOptions(true)
            }
        }}}>
            <div style={image} className="d-flex">
                <div className="w-100 text-white bTitleText pt-1 pb-1">
                    {data.titleActive &&
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <InlineEditableText isResult={props.isResult} name="title"
                                                setText={setValue}>{data.title ? data.title : '<h1 class="ql-align-center"><span style="color: rgb(0, 0, 0);">Social</span></h1>'}</InlineEditableText>
                        </div>
                    </div>
                    }
                    {data.subtitleActive &&
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <InlineEditableText isResult={props.isResult} name="subtitle"
                                                setText={setValue}>{data.subtitle ? data.subtitle : '<p class="ql-align-center"><span style="color: rgb(0, 0, 0);">Sub title</span></p>'}</InlineEditableText>
                        </div>
                    </div>
                    }
                    <div className="row justify-content-center pr-2 pl-2">
                        {data.facebookActive &&
                        <div className="col text-center mt-2">
                            <Button style={{backgroundColor:"#4267B2"}} className={`border-0 ${data.rounded?'btn-pill':''}`} onClick={()=>window.location.href=data.facebook}><nobr><FontAwesomeIcon size={data.size+"x"} icon={faFacebook} className="cursor-pointer"/>{data.nameActive?' Facebook':''}</nobr></Button>
                        </div>
                        }
                        {data.twitterActive &&
                        <div className="col text-center mt-2">
                            <Button style={{backgroundColor:"#1DA1F2"}} className={`border-0 ${data.rounded?'btn-pill':''}`} onClick={()=>window.location.href=data.twitter}><nobr><FontAwesomeIcon size={data.size+"x"} icon={faTwitter} className="cursor-pointer"/>{data.nameActive?' Twitter':''}</nobr></Button>
                        </div>
                        }
                        {data.vkActive &&
                        <div className="col text-center mt-2">
                            <Button style={{backgroundColor:"#4C75A3"}} className={`border-0 ${data.rounded?'btn-pill':''}`} onClick={()=>window.location.href=data.vk}><nobr><FontAwesomeIcon size={data.size+"x"} icon={faVk} className="cursor-pointer"/>{data.nameActive?' Vkontakte':''}</nobr></Button>
                        </div>
                        }
                        {data.odnoklassnikiActive &&
                        <div className="col text-center mt-2">
                            <Button style={{backgroundColor:"#f97400"}} className={`pr-3 pl-3 border-0 ${data.rounded?'btn-pill':''}`} onClick={()=>window.location.href=data.odnoklassniki}><nobr><FontAwesomeIcon size={data.size+"x"} icon={faOdnoklassniki} className="cursor-pointer"/>{data.nameActive?' Odnoklassniki':''}</nobr></Button>
                        </div>
                        }
                        {data.pinterestActive &&
                        <div className="col text-center mt-2">
                            <Button style={{backgroundColor:"#E60023"}} className={`border-0 ${data.rounded?'btn-pill':''}`} onClick={()=>window.location.href=data.pinterest}><nobr><FontAwesomeIcon size={data.size+"x"} icon={faPinterest} className="cursor-pointer"/>{data.nameActive?' Pinterest':''}</nobr></Button>
                        </div>
                        }
                        {data.emailActive &&
                        <div className="col text-center mt-2">
                            <Button style={{backgroundColor:"#006ee6"}} className={`border-0 ${data.rounded?'btn-pill':''}`} onClick={()=>window.location.href="mailto:"+data.email}><nobr><FontAwesomeIcon size={data.size+"x"} icon={faEnvelope} className="cursor-pointer"/>{data.nameActive?' Email':''}</nobr></Button>
                        </div>
                        }
                    </div>
                </div>
            </div>
            {showOption &&
            <div ref={ref} className="pb-5" style={{position:"absolute",left:"101%",backgroundColor:"black",top:0,zIndex:"1000",borderRadius:"5px",width:"250px",textAlign:"center",padding:"10px"}}>
                <div className="row mt-3">
                    <div className="col-12 text-left">
                        <span className="text-white">Size</span>
                    </div>
                    <div className="col-12 text-center">
                        <Slider
                            styles={{
                                active: {
                                    backgroundColor: '#86d3ff'
                                },
                                thumb: {
                                    width: 20,
                                    height: 20,
                                    backgroundColor: '#2693e6'
                                },
                                disabled: {
                                    opacity: 0.5
                                }
                            }}
                            xmax={5} xmin={1} xstep={1} axis="x" x={data.size} onChange={({ x }) => setValue('size',x)} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Platform name</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            checked={data.nameActive}
                            onChange={(nextChecked)=>setValue('nameActive',nextChecked)}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Rounded button</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            checked={data.rounded}
                            onChange={(nextChecked)=>setValue('rounded',nextChecked)}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Facebook</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            checked={data.facebookActive}
                            onChange={(nextChecked)=>setValue('facebookActive',nextChecked)}
                        />
                    </div>
                </div>
                {data.facebookActive &&
                <div className="row">
                    <div className="col">
                        <input type="text" className="form-control" value={data.facebook} onChange={(e)=>setValue('facebook',e.target.value)}/>
                    </div>
                    <div className="col-auto">
                        <InputColor
                            initialValue={data.facebookColor?data.facebookColor:"#4267B2"}
                            onChange={(color)=>setValue('facebookColor',color.hex)}
                            placement="right"
                        />
                    </div>
                </div>
                }
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Twitter</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            checked={data.twitterActive}
                            onChange={(nextChecked)=>setValue('twitterActive',nextChecked)}
                        />
                    </div>
                </div>
                {data.twitterActive &&
                <div className="row">
                    <div className="col">
                        <input type="text" className="form-control" value={data.twitter} onChange={(e)=>setValue('twitter',e.target.value)}/>
                    </div>
                    <div className="col-auto">
                        <InputColor
                            initialValue={data.twitterColor?data.twitterColor:"#1DA1F2"}
                            onChange={(color)=>setValue('twitterColor',color.hex)}
                            placement="right"
                        />
                    </div>
                </div>
                }
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">VK</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            checked={data.vkActive}
                            onChange={(nextChecked)=>setValue('vkActive',nextChecked)}
                        />
                    </div>
                </div>
                {data.vkActive &&
                <div className="row">
                    <div className="col">
                        <input type="text" className="form-control" value={data.vk} onChange={(e)=>setValue('vk',e.target.value)}/>
                    </div>
                    <div className="col-auto">
                        <InputColor
                            initialValue={data.vkColor?data.vkColor:"#4C75A3"}
                            onChange={(color)=>setValue('vkColor',color.hex)}
                            placement="right"
                        />
                    </div>
                </div>
                }
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Odnoklassniki</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            checked={data.odnoklassnikiActive}
                            onChange={(nextChecked)=>setValue('odnoklassnikiActive',nextChecked)}
                        />
                    </div>
                </div>
                {data.odnoklassnikiActive &&
                <div className="row">
                    <div className="col">
                        <input type="text" className="form-control" value={data.odnoklassniki} onChange={(e)=>setValue('odnoklassniki',e.target.value)}/>
                    </div>
                    <div className="col-auto">
                        <InputColor
                            initialValue={data.odnoklassnikiColor?data.odnoklassnikiColor:"#f97400"}
                            onChange={(color)=>setValue('odnoklassnikiColor',color.hex)}
                            placement="right"
                        />
                    </div>
                </div>
                }
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Pinterest</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            checked={data.pinterestActive}
                            onChange={(nextChecked)=>setValue('pinterestActive',nextChecked)}
                        />
                    </div>
                </div>
                {data.pinterestActive &&
                <div className="row">
                    <div className="col">
                        <input type="text" className="form-control" value={data.pinterest} onChange={(e)=>setValue('pinterest',e.target.value)}/>
                    </div>
                    <div className="col-auto">
                        <InputColor
                            initialValue={data.pinterestColor?data.pinterestColor:"#E60023"}
                            onChange={(color)=>setValue('pinterestColor',color.hex)}
                            placement="right"
                        />
                    </div>
                </div>
                }
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Email</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            checked={data.emailActive}
                            onChange={(nextChecked)=>setValue('emailActive',nextChecked)}
                        />
                    </div>
                </div>
                {data.emailActive &&
                <div className="row">
                    <div className="col">
                        <input type="text" className="form-control" value={data.email} onChange={(e)=>setValue('email',e.target.value)}/>
                    </div>
                    <div className="col-auto">
                        <InputColor
                            initialValue={data.emailColor?data.emailColor:"#006ee6"}
                            onChange={(color)=>setValue('emailColor',color.hex)}
                            placement="right"
                        />
                    </div>
                </div>
                }
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Title</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            checked={data.titleActive}
                            onChange={(nextChecked)=>setValue('titleActive',nextChecked)}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Subtitle</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                checked={data.subtitleActive} onChange={(nextChecked)=>setValue('subtitleActive',nextChecked)}/>
                    </div>
                </div>
                <span className="text-white">Padding</span>
                <div className="row mt-3">
                    <div className="col-12 text-left">
                        <span className="text-white">Top</span>
                    </div>
                    <div className="col-12 text-center">
                        <Slider
                            styles={{
                                active: {
                                    backgroundColor: '#86d3ff'
                                },
                                thumb: {
                                    width: 20,
                                    height: 20,
                                    backgroundColor: '#2693e6'
                                },
                                disabled: {
                                    opacity: 0.5
                                }
                            }}
                            xmax={50} xmin={0} xstep={1} axis="x" x={(data.paddingTop || data.paddingTop === 0)?data.paddingTop:0} onChange={({ x }) => setValue('paddingTop',x)} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 text-left">
                        <span className="text-white">Bottom</span>
                    </div>
                    <div className="col-12 text-center">
                        <Slider
                            styles={{
                                active: {
                                    backgroundColor: '#86d3ff'
                                },
                                thumb: {
                                    width: 20,
                                    height: 20,
                                    backgroundColor: '#2693e6'
                                },
                                disabled: {
                                    opacity: 0.5
                                }
                            }}
                            xmax={50} xmin={0} xstep={1} axis="x" x={(data.paddingBottom || data.paddingBottom === 0)?data.paddingBottom:0} onChange={({ x }) => setValue('paddingBottom',x)} />
                    </div>
                </div>
                <span className="text-white">Background</span>
                <div className="row mt-3">
                    <div className="col text-left">
                        <InputColor
                            initialValue={data.bgColor?data.bgColor:"#ffffff"}
                            onChange={(color)=>setValue('bgColor',color.hex)}
                            placement="right"
                        />
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default Social
