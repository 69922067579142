import React,{useState} from "react";
import {
    Button, Card,
    CardBody,
    Container,
    FormGroup,
    Label,
} from "reactstrap";
import {
    AvForm,
    AvInput
} from "availity-reactstrap-validation";
import API from "../../utils/API";
import { useHistory } from "react-router-dom";
import {toastr} from "react-redux-toastr";

const ChangePassword = (props) => {
    const [user, setUser] = useState({password:null});
    const [error, setError] = useState(null)

    let history = useHistory();

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
        setError(null)
    };

    const submit = () => {
        API.put(`/admin/user/${props.match.params.id}/password`,{password:user.password})
            .then((response) => {
                toastr.success('Success', 'Password was successfully changed')
                history.push(`/user/item/${props.match.params.id}`)
            })
            .catch(error => {
                setError('Invalid data')
            })
    }

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Change password</h1>
            <Card>
                <CardBody>
                    {error &&
                        <div className="text-center text-danger">{error}</div>
                    }
                    <AvForm onValidSubmit={submit}>
                        <FormGroup>
                            <Label>Password</Label>
                            <AvInput onChange={handleChange}
                                     name="password"
                                     type="password"
                                     value={user.password}
                                     placeholder="Password"
                                     validate={{
                                         required: {value: true},
                                         minLength: {value: 8},
                                         maxLength: {value: 32}
                                     }}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Password confirm</Label>
                            <AvInput onChange={handleChange} type="password" name="passwordConfirm" value={user.passwordConfirm} placeholder="Confirm password"
                                     validate={{match:{value:'password'},required: {value: true}}}
                            />
                        </FormGroup>
                        <div className="text-center">
                            <Button color="primary">Change password</Button>
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        </Container>
    )
};

export default ChangePassword;
