import React,{useEffect,useState,useRef} from "react"
import API from "../../../../utils/API"
import InputColor from 'react-input-color'
import Slider from 'react-input-slider'
import {useDispatch} from "react-redux";

const Image = (props) => {

    const [showOption, setShowOptions] = useState(false)
    const [data, setData] = useState((props.data)?props.data:props.defaultdata?props.defaultdata:{titleActive:true,subtitleActive:true,textActive:true,imageSize:80,image:'https://gettow.s3.amazonaws.com/image/61c16e45f1bb6.jpeg'})

    const [clickedOutside, setClickedOutside] = useState(false);

    const ref = useRef();

    const handleClickOutside = e => {
        if (ref && ref.current && !ref.current.contains(e.target)) {
            setClickedOutside(true)
            setShowOptions(false)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    },[]);

    const setValue = (name,value) => {
        let newData = {
            ...data,
            [name]: value
        }

        setData(newData)
        props.setValue(newData)
    }

    let mainStyle = {
        backgroundColor: data.bgColor?data.bgColor:'#FFFFFF',
        border:(props.isResult)?"dashed 1px #64afb9":"0",
        paddingTop: (data.paddingTop || data.paddingTop === 0)?data.paddingTop:0,
        paddingBottom: (data.paddingBottom || data.paddingBottom === 0)?data.paddingBottom:0,
    }

    const handleFileSelect = (event) => {
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = (e) => {
            API.post('/imageadmin',{image:e.target.result}).then((response) => {
                setValue('image',response.data.data.url)
            });
        }
    }

    return (
        <div style={{position:"relative"}} onClick={(e)=>{if (e.target.tagName.toLowerCase() === 'div'){
            if (props.isResult) {
                setShowOptions(true)
            }
        }}}>
            <div style={mainStyle} className="d-flex">
                <div className="w-100 text-white bTitleText">
                    {data.image &&
                        <div className="row justify-content-center">
                            <div className="col text-center">
                                <img src={data.image} className="img-fluid" style={{width:data.imageSize+"%"}}/>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {showOption &&
                <div ref={ref} className="pb-5" style={{position:"absolute",left:"101%",backgroundColor:"black",top:0,zIndex:"1000",borderRadius:"5px",width:"250px",textAlign:"center",padding:"10px"}}>
                    <div className="row">
                        <div className="col">
                            <span className="text-white">Settings</span>
                        </div>
                    </div>
                    <span className="text-white">Padding</span>
                    <div className="row mt-3">
                        <div className="col-12 text-left">
                            <span className="text-white">Top</span>
                        </div>
                        <div className="col-12 text-center">
                            <Slider
                                styles={{
                                    active: {
                                        backgroundColor: '#86d3ff'
                                    },
                                    thumb: {
                                        width: 20,
                                        height: 20,
                                        backgroundColor: '#2693e6'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                                xmax={50} xmin={0} xstep={1} axis="x" x={(data.paddingTop || data.paddingTop === 0)?data.paddingTop:0} onChange={({ x }) => setValue('paddingTop',x)} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 text-left">
                            <span className="text-white">Bottom</span>
                        </div>
                        <div className="col-12 text-center">
                            <Slider
                                styles={{
                                    active: {
                                        backgroundColor: '#86d3ff'
                                    },
                                    thumb: {
                                        width: 20,
                                        height: 20,
                                        backgroundColor: '#2693e6'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                                xmax={50} xmin={0} xstep={1} axis="x" x={(data.paddingBottom || data.paddingBottom === 0)?data.paddingBottom:0} onChange={({ x }) => setValue('paddingBottom',x)} />
                        </div>
                    </div>
                    <div className="text-white text-left">Background</div>
                    <div className="row mt-3">
                        <div className="col text-left">
                            <InputColor
                                initialValue={data.bgColor?data.bgColor:"#ffffff"}
                                onChange={(color)=>setValue('bgColor',color.hex)}
                                placement="right"
                            />
                        </div>
                    </div>
                    <span className="text-white">Image</span>
                    <div className="row mt-3">
                        <div className="col text-left">
                            {data.image &&
                                <img src={data.image} className="img-fluid"/>
                            }
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-6 text-left">
                            <input type="file" onChange={handleFileSelect}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 text-left">
                            <span className="text-white">Image size</span>
                        </div>
                        <div className="col-12 text-center">
                            <Slider
                                styles={{
                                    active: {
                                        backgroundColor: '#86d3ff'
                                    },
                                    thumb: {
                                        width: 20,
                                        height: 20,
                                        backgroundColor: '#2693e6'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                                xmax={100} xmin={0} xstep={1} axis="x" x={(data.imageSize || data.imageSize === 0)?data.imageSize:100} onChange={({ x }) => setValue('imageSize',x)} />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Image
