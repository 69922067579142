import React from "react"
import {Button} from "reactstrap";

const PreviewApp = ({id}) => {
    const openApp = (id) => {
        window.open(process.env.REACT_APP_URL+"/nativeapp/"+id, 'app', 'toolbar=0,status=0,width=428,height=926');
    }

    return (
        <Button size={"sm"} color={"info"} onClick={()=>openApp(id)}>Preview app</Button>
    )
}

export default PreviewApp
