import React,{useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import API from "../../../../../utils/API";

const MenuItem = ({data,setValue,deleteMenuElement,categories}) => {
    const [currentData, setCurrentData] = useState(data)

    const changeValue = (name, value) => {
        let currentDataValues = currentData
        currentDataValues[name] = value
        setCurrentData(currentDataValues)
        setValue(currentDataValues.id,name,value)
    }

    const handleFileSelect = (event) => {
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = (e) => {
            API.post('/imageadmin',{image:e.target.result}).then((response) => {
                changeValue('image',response.data.data.url)
            });
        }
    }

    return (
        <div>
            <hr style={{backgroundColor:"white",height:"1px",width:"100%"}}/>
            <div className="row">
                <div className="col-auto">
                    <span className="text-white font-size-lg">{currentData.name}</span>
                </div>
                <div className="col text-right">
                    <FontAwesomeIcon icon={faTrash} color={"white"} className="cursor-pointer" onClick={(e)=>{deleteMenuElement(currentData.id)}}/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {currentData.image ?
                        <>
                        <img src={currentData.image} className="img-fluid"/>
                            <div className="row justify-content-end mt-2">
                                <div className="col-auto">
                                    <FontAwesomeIcon icon={faEdit} color={"white"} className="cursor-pointer"/>
                                    <input type="file" onChange={handleFileSelect} style={{display: "none"}}/>
                                </div>
                                <div className="col-auto">
                                    <FontAwesomeIcon icon={faTrash} color={"red"} onClick={() => changeValue('image', null)}
                                                     className="cursor-pointer"/>
                                </div>
                            </div>
                        </>
                        :
                        <div className="text-center">
                            <label>
                                <span className="btn text-white btn-secondary">Add image</span>
                                <input type="file" onChange={handleFileSelect} style={{display: "none"}}/>
                            </label>
                        </div>
                    }
                </div>
            </div>
            <div className="mt-3">
                <div className="text-white text-left mb-1">Name</div>
                <input type="text" value={currentData.name} className="form-control" onChange={(e)=>changeValue('name',e.target.value)}/>
            </div>
            <div className="mt-3">
                <div className="text-white text-left mb-1">Description</div>
                <textarea className="form-control" onChange={(e)=>changeValue('description',e.target.value)}>{currentData.description}</textarea>
            </div>
            <div className="mt-3">
                <div className="text-white text-left mb-1">Price</div>
                <input type="text" value={currentData.price} className="form-control" onChange={(e)=>changeValue('price',e.target.value)}/>
            </div>
            {(categories && categories.length) &&
                <div className="mt-3">
                    <div className="text-white text-left mb-1">Category</div>
                    <select value={currentData.category} className="form-control" onChange={(e)=>changeValue('category',e.target.value)}>
                        <option value={''}>Select...</option>
                        {categories.map((category,index) => {
                            if (category.name) {
                                return <option value={category.name} key={index}>{category.name}</option>
                            }else {
                                return <></>
                            }
                        })}
                    </select>
                </div>
            }
        </div>
    )
}

export default MenuItem
