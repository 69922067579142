import React,{useState} from "react"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Slide = ({items}) => {
    const [currentItems, setCurrentItems] = useState(items)

    const settings = {
        dots: false,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2
    };

    return (
        <div>
            {currentItems.length > 0 &&
                <div className="text-black-50">
                    <div className="m-2">
                        <Slider {...settings}>
                        {
                            currentItems.map((inCategoryItem,inCategoryItemsKey)=>{
                                return (
                                    <div key={inCategoryItemsKey} className="p-2">
                                        {inCategoryItem.image &&
                                            <img className="img-fluid" src={inCategoryItem.image}/>
                                        }
                                    </div>
                                )
                            })
                        }
                        </Slider>
                    </div>
                </div>
            }
        </div>
    )
}

export default Slide
