import React,{useEffect,useState,useRef} from "react"
import bgImage from "../../../../../assets/blocks/header1/bg.jpeg"
import InlineEditableText from "../../InlineEditableText"
import {Input} from "reactstrap"
import API from "../../../../../utils/API"
import InputColor from 'react-input-color'
import Slider from 'react-input-slider'
import { v4 as uuidv4 } from 'uuid';
import Switch from "react-switch";
import Logo from "./Logo";
import Grid from "./Styles/Grid";
import Slide from "./Styles/Slide";

const LogoShowcase = (props) => {
    const [showOption, setShowOptions] = useState(false)
    const [data, setData] = useState(props.data?props.data:props.defaultdata?props.defaultdata:{bgColor:"#ffffff",bgType:'color',titleActive:true,subtitleActive:true,logos:[],style:'grid'})

    const ref = useRef();

    const handleClickOutside = e => {
        if (ref && ref.current && !ref.current.contains(e.target)) {
            setShowOptions(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    },[]);

    const setValue = (name,value) => {
        let newData = {
            ...data,
            [name]: value
        }

        setData(newData)
        props.setValue(newData)
    }

    const addLogoElement = () => {
        let currentLogoElements = data.logos
        if (!currentLogoElements) {
            currentLogoElements = []
        }
        currentLogoElements.push({id:uuidv4()})
        setValue('logos',currentLogoElements)
    }

    const deleteLogoElement = (id) => {
        let currentLogoElements = data.logos
        if (currentLogoElements) {
            let newLogoElements = [], n = currentLogoElements.length, i
            for (i=0; i < n; i++) {
                if (currentLogoElements[i].id !== id) {
                    newLogoElements.push(currentLogoElements[i])
                }
            }
            setValue('logos',newLogoElements)
        }
    }

    const setLogoElementOptions = (id,name,value) => {
        let currentLogoElements = data.logos
        if (currentLogoElements) {
            let newLogoElements = [], n = currentLogoElements.length, i
            for (i=0; i < n; i++) {
                if (currentLogoElements[i].id === id) {
                    currentLogoElements[i][name] = value
                }

                newLogoElements.push(currentLogoElements[i])

            }
            setValue('logos',newLogoElements)
        }
    }

    let image = {
        backgroundRepeat: "no-repeat",
        backgroundPosition : "center",
        backgroundSize: "cover",
        backgroundImage: (!data.bgType || data.bgType === 'image')?`url(${data.bgImage?data.bgImage:bgImage})`:'',
        backgroundColor: (data.bgType && data.bgType === 'color')?(data.bgColor?data.bgColor:'#ffffff'):'',
        position:"relative",
        border:(props.isResult)?"dashed 1px #64afb9":"0",
        paddingTop: (data.paddingTop || data.paddingTop === 0)?data.paddingTop:0,
        paddingBottom: (data.paddingBottom || data.paddingBottom === 0)?data.paddingBottom:0,
    }

    let overlay = {
        background: data.overlayColor?data.overlayColor:"#000000",
        opacity: (data.opacity || data.opacity === 0)?data.opacity:0,
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0
    }

    const handleFileSelect = (event) => {
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = (e) => {
            API.post('/imageadmin',{image:e.target.result}).then((response) => {
                setValue('bgImage',response.data.data.url)
            });
        }
    }

    return (
        <div style={{position:"relative"}} onClick={(e)=>{if (e.target.tagName.toLowerCase() === 'div'){
            if (props.isResult) {
                setShowOptions(true)
            }
        }}}>
            <div style={image} className="d-flex">
                <div style={overlay}/>
                <div className="w-100 text-white bTitleText">
                    {data.titleActive &&
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <InlineEditableText isResult={props.isResult} name="title"
                                                    setText={setValue}>{data.title ? data.title : '<h1 class="ql-align-center"><span style="color: rgb(0, 0, 255);">View in action</span></h1>'}</InlineEditableText>
                            </div>
                        </div>
                    }
                    {data.subtitleActive &&
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <InlineEditableText isResult={props.isResult} name="subtitle"
                                                    setText={setValue}>{data.subtitle ? data.subtitle : '<p class="ql-align-center"><span style="color: rgb(0, 0, 0);">Sub title</span></p>'}</InlineEditableText>
                            </div>
                        </div>
                    }
                    {data.style === 'grid' &&
                        <Grid items={data.logos}/>
                    }
                    {data.style === 'slider' &&
                        <Slide items={data.logos}/>
                    }
                </div>
            </div>
            {showOption &&
                <div ref={ref} className="pb-5" style={{position:"absolute",left:"101%",backgroundColor:"black",top:0,zIndex:"1000",borderRadius:"5px",width:"250px",textAlign:"center",padding:"10px"}}>
                    <div className="row">
                        <div className="col">
                            <span className="text-white">Settings</span>
                        </div>
                    </div>
                    <span className="text-white">Style</span>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Grid</span>
                        </div>
                        <div className="col-6 text-right">
                            <Input type="radio" style={{width:25,height:25}} name="style" checked={data.style==='grid'} onChange={() => setValue('style','grid')}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Slider</span>
                        </div>
                        <div className="col-6 text-right">
                            <Input type="radio" style={{width:25,height:25}} name="style" checked={data.style==='slider'} onChange={() => setValue('style','slider')}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Title</span>
                        </div>
                        <div className="col-6 text-right">
                            <Switch
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                checked={data.titleActive}
                                onChange={(nextChecked)=>setValue('titleActive',nextChecked)}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Subtitle</span>
                        </div>
                        <div className="col-6 text-right">
                            <Switch onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={30}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                    checked={data.subtitleActive} onChange={(nextChecked)=>setValue('subtitleActive',nextChecked)}/>
                        </div>
                    </div>
                    <span className="text-white">Padding</span>
                    <div className="row mt-3">
                        <div className="col-12 text-left">
                            <span className="text-white">Top</span>
                        </div>
                        <div className="col-12 text-center">
                            <Slider
                                styles={{
                                    active: {
                                        backgroundColor: '#86d3ff'
                                    },
                                    thumb: {
                                        width: 20,
                                        height: 20,
                                        backgroundColor: '#2693e6'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                                xmax={50} xmin={0} xstep={1} axis="x" x={(data.paddingTop || data.paddingTop === 0)?data.paddingTop:0} onChange={({ x }) => setValue('paddingTop',x)} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 text-left">
                            <span className="text-white">Bottom</span>
                        </div>
                        <div className="col-12 text-center">
                            <Slider
                                styles={{
                                    active: {
                                        backgroundColor: '#86d3ff'
                                    },
                                    thumb: {
                                        width: 20,
                                        height: 20,
                                        backgroundColor: '#2693e6'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                                xmax={50} xmin={0} xstep={1} axis="x" x={(data.paddingBottom || data.paddingBottom === 0)?data.paddingBottom:0} onChange={({ x }) => setValue('paddingBottom',x)} />
                        </div>
                    </div>
                    <div className="text-white mt-2">Block background</div>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Image</span>
                        </div>
                        <div className="col-6 text-right">
                            <Input type="radio" style={{width:25,height:25}} name="bgtype" checked={data.bgType==='image'} onChange={() => setValue('bgType','image')}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Color</span>
                        </div>
                        <div className="col-6 text-right">
                            <Input type="radio" style={{width:25,height:25}} name="bgtype" checked={data.bgType==='color'} onChange={() => setValue('bgType','color')} />
                        </div>
                    </div>
                    {data.bgType === 'image' &&
                        <>
                            <div className="row mt-3">
                                <div className="col text-left">
                                    {data.bgImage &&
                                        <img src={data.bgImage} className="img-fluid"/>
                                    }
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-6 text-left">
                                    <input type="file" onChange={handleFileSelect}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 text-left">
                                    <span className="text-white">Overlay</span>
                                </div>
                                <div className="col-6 text-right">
                                    <InputColor
                                        initialValue={data.overlayColor?data.overlayColor:"#000000"}
                                        onChange={(color)=>setValue('overlayColor',color.hex)}
                                        placement="right"
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 text-left">
                                    <span className="text-white">Opacity</span>
                                </div>
                                <div className="col-12 text-center">
                                    <Slider
                                        styles={{
                                            active: {
                                                backgroundColor: '#86d3ff'
                                            },
                                            thumb: {
                                                width: 20,
                                                height: 20,
                                                backgroundColor: '#2693e6'
                                            },
                                            disabled: {
                                                opacity: 0.5
                                            }
                                        }}
                                        xmax={1} xmin={0} xstep={0.1} axis="x" x={(data.opacity || data.opacity === 0)?data.opacity:0} onChange={({ x }) => setValue('opacity',x)} />
                                </div>
                            </div>
                        </>
                    }
                    {data.bgType === 'color' &&
                        <>
                            <div className="row mt-3">
                                <div className="col text-left">
                                    <InputColor
                                        initialValue={data.bgColor?data.bgColor:"#000000"}
                                        onChange={(color)=>setValue('bgColor',color.hex)}
                                        placement="right"
                                    />
                                </div>
                            </div>

                        </>
                    }
                    <hr style={{backgroundColor:"white",height:"1px",width:"100%"}}/>
                    <div className="text-center">
                        <button className="btn btn-success" onClick={()=>addLogoElement()}>Add logo</button>
                    </div>
                    {data.logos.length > 0 &&
                        <>
                            {data.logos.map((logo,index)=>{
                                return <Logo key={index} data={logo} setValue={setLogoElementOptions} deleteMenuElement={deleteLogoElement}/>
                            })}
                        </>
                    }
                </div>
            }
        </div>
    )
}

export default LogoShowcase
