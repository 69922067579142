import React,{useState,useEffect} from "react";
import {useHistory} from "react-router-dom";
import API from "../../utils/API";
import {toastr} from "react-redux-toastr";
import {Button, Card, CardBody, Container, FormGroup, Label} from "reactstrap";
import {AvField, AvForm, AvInput} from "availity-reactstrap-validation";

const CategoryEdit = (props) => {
    const [category, setCategory] = useState({title:null,priority:1});
    const [error, setError] = useState(null)

    let history = useHistory();

    const handleChange = (e) => {
        setCategory({ ...category, [e.target.name]: e.target.value })
        setError(null)
    };

    const submit = () => {
        if (props.match.params.id) {
            API.post('/admin/category/save/'+props.match.params.id,category)
                .then((response) => {
                    toastr.success('Success', 'Category was successfully saved')
                    history.push("/blocks/categories")
                })
                .catch(error => {
                    setError('Invalid category data')
                })
        }else {
            API.post('/admin/category/add',category)
                .then((response) => {
                    toastr.success('Success', 'Category was successfully saved')
                    history.push("/blocks/categories")
                })
                .catch(error => {
                    setError('Invalid category data')
                })

        }
    }

    useEffect(()=>{
        if (props.match.params.id) {
            API.get('/admin/category/item/'+props.match.params.id)
                .then((response) => {
                    setCategory(response.data.data)
                })
        }
    },props.match.params.id)

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">{props.match.params.id?'Edit':'New'} Block category</h1>
            <Card>
                <CardBody>
                    {error &&
                    <div className="text-center text-danger">{error}</div>
                    }
                    <AvForm onValidSubmit={submit}>
                        <FormGroup>
                            <Label>Title</Label>
                            <AvInput onChange={handleChange} required type="text" name="title" value={category.title} placeholder="Category title" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Priority</Label>
                            <AvField
                                type="select"
                                name="priority"
                                value={category.priority}
                                onChange={handleChange}
                            >
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                                <option value={8}>8</option>
                                <option value={9}>9</option>
                                <option value={10}>10</option>
                                <option value={11}>11</option>
                                <option value={12}>12</option>
                                <option value={13}>13</option>
                                <option value={14}>14</option>
                                <option value={15}>15</option>
                                <option value={16}>16</option>
                                <option value={17}>17</option>
                                <option value={18}>18</option>
                                <option value={19}>19</option>
                                <option value={20}>20</option>
                            </AvField>
                        </FormGroup>
                        <div className="text-center">
                            <Button color="primary">Save</Button>
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        </Container>
    )
}

export default CategoryEdit