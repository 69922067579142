import React, {useEffect, useState} from "react"
import API from "../../utils/API";
import {Button, Card, Col, Container, Row, Table} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import blockFactory from "../builder/blockFactory";
import {Edit, Edit2, Settings} from "react-feather";
import {useHistory} from "react-router-dom";

const TemplatesList = () => {

    const [templates, setTemplates] = useState(null)

    let history = useHistory();

    useEffect(() => {
        API.get('/admin/apptemplate/all').then((response) => {
            setTemplates(response.data.data)
        });
    },[])

    const configureTemplateFeature = (id) => {
        history.push("/templates/configure/"+id);
    }

    const editTemplate = (id) => {
        history.push("/templates/edit/"+id);
    }

    const newTemplate = () => {
        history.push("/templates/newtemplate")
    }

    return (
        <Container fluid className="p-0">
            <Button color="primary" onClick={newTemplate} className="float-right mt-n1">
                <FontAwesomeIcon icon={faPlus}/> New template
            </Button>
            <h1 className="h3 mb-3">Templates</h1>
            <Row>
                <Col>
                    <Card>
                        {(templates && templates.length > 0) &&
                            <div className="table-responsive">
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th style={{width:"100px"}}>Image</th>
                                        <th>Edit</th>
                                        <th>Configure</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        templates.map((template, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{template.title}</td>
                                                    <td>{template.status === "1" ?
                                                        <span className="text-success">Published</span> :
                                                        <span className="text-danger">Unpublished</span>
                                                    }</td>
                                                    <td>{template.logo &&
                                                        <img src={template.logo} className="img-fluid"/>}</td>
                                                    <td className="table-action">
                                                        <Edit2 onClick={() => {editTemplate(template.id)}} className="align-middle mr-1 cursor-pointer text-primary" size={18} />
                                                    </td>
                                                    <td className="table-action">
                                                        <Settings onClick={() => {configureTemplateFeature(template.appid)}} className="align-middle mr-1 cursor-pointer text-primary" size={18} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default TemplatesList
