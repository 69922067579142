import React,{useState,useEffect} from "react";
import {useHistory} from "react-router-dom";
import API from "../../utils/API";
import {Button, Card, CardBody, Col, Container, Row, Table} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Edit2,Trash} from "react-feather";

const SubscriptionPlans = (props) => {
    const [plans, setPlans] = useState(null)
    let history = useHistory();

    const loadBlocks = () => {
        API.get('/admin/billing/plans').then((response) => {
            setPlans(response.data.data)
        });
    }

    useEffect(() => {
        loadBlocks();
    }, [])

    const editPlan = (id) => {
        history.push("/settings/subscriptioplan/"+id);
    }

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Subscription plans</h1>
            <Row>
                <Col>
                    <Card>
                        {(plans && plans.length > 0) &&
                            <div className="table-responsive">
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Price</th>
                                        <th>Interval</th>
                                        <th>Description</th>
                                        <th>Edit</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        plans.map((plan, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{plan.title}</td>
                                                    <td>${plan.price}</td>
                                                    <td><span className="badge badge-success">{plan.pricerecurringinterval}</span></td>
                                                    <td>{plan.description?.length > 0 &&
                                                        plan.description.split("\n").map(function(item) {
                                                            return <>{item}<br/></>
                                                        })
                                                    }</td>
                                                    <td className="table-action">
                                                        <Edit2 onClick={() => {editPlan(plan.id)}} className="align-middle mr-1 cursor-pointer text-primary" size={18} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default SubscriptionPlans
