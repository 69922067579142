import React,{useState,useEffect} from "react"
import API from "../../utils/API";

const SubmissionLogs = ({submissionId}) => {
    const [logs,setLogs] = useState(null)

    useEffect(() => {
        API.post('/admin/appsubmit/comments/'+submissionId).then((response) => {
            setLogs(response.data.data)
        });
    }, [submissionId])

    return (
        <div>
            {logs &&
                <>
                    <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Platform</th>
                            <th>Log</th>
                        </tr>
                    </thead>
                    {logs.map((log,index) => {
                    return (
                        <tr key={index}>
                            <td>{log.appsubmitlog.date}</td>
                            <td>{log.appplatform.title}</td>
                            <td>{log.appsubmitlog.content}</td>
                        </tr>
                    )
                    })}
                    </table>
                </>
            }
        </div>
    )
}

export default SubmissionLogs
