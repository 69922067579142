import React,{useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import API from "../../../../../utils/API";

const Logo = ({data,setValue,deleteMenuElement,categories}) => {
    const [currentData, setCurrentData] = useState(data)

    const changeValue = (name, value) => {
        let currentDataValues = currentData
        currentDataValues[name] = value
        setCurrentData(currentDataValues)
        setValue(currentDataValues.id,name,value)
    }

    const handleFileSelect = (event) => {
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = (e) => {
            API.post('/imageadmin',{image:e.target.result}).then((response) => {
                changeValue('image',response.data.data.url)
            });
        }
    }

    return (
        <div>
            <hr style={{backgroundColor:"white",height:"1px",width:"100%"}}/>
            <div className="row">
                <div className="col-auto">
                    <span className="text-white font-size-lg">{currentData.name}</span>
                </div>
                <div className="col text-right">
                    <FontAwesomeIcon icon={faTrash} color={"white"} className="cursor-pointer" onClick={(e)=>{deleteMenuElement(currentData.id)}}/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {currentData.image ?
                        <>
                            <img src={currentData.image} className="img-fluid"/>
                            <div className="row justify-content-end mt-2">
                                <div className="col-auto">
                                    <FontAwesomeIcon icon={faEdit} color={"white"} className="cursor-pointer"/>
                                    <input type="file" onChange={handleFileSelect} style={{display: "none"}}/>
                                </div>
                                <div className="col-auto">
                                    <FontAwesomeIcon icon={faTrash} color={"red"} onClick={() => changeValue('image', null)}
                                                     className="cursor-pointer"/>
                                </div>
                            </div>
                        </>
                        :
                        <div className="text-center">
                            <label>
                                <span className="btn text-white btn-secondary">Add logo</span>
                                <input type="file" onChange={handleFileSelect} style={{display: "none"}}/>
                            </label>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Logo
