import React,{useState} from "react";
import {
    Button, Card,
    CardBody,
    Container,
    FormGroup,
    Label,
} from "reactstrap";
import {
    AvField,
    AvForm,
    AvInput
} from "availity-reactstrap-validation";
import API from "../../utils/API";
import { useHistory } from "react-router-dom";
import {toastr} from "react-redux-toastr";

const NewUser = (props) => {
    const [user, setUser] = useState({name:null,email:null,password:null});
    const [error, setError] = useState(null)

    let history = useHistory();

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
        setError(null)
    };

    const submit = () => {
        API.post('/admin/user',{name:user.name,email:user.email,password:user.password})
            .then((response) => {
                toastr.success('Success', 'User was successfully added')
                history.push(`/user/item/${response.data.data.id}`)
            })
            .catch(error => {
                setError('Duplicate email')
            })
    }

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">{props.match.params.id?'Edit':'New'} user</h1>
            <Card>
                <CardBody>
                    {error &&
                        <div className="text-center text-danger">{error}</div>
                    }
                    <AvForm onValidSubmit={submit}>
                        <FormGroup>
                            <Label for={'name'}>Name</Label>
                            <AvInput onChange={handleChange} type="text" name="name" value={user.name} placeholder="User full name"
                                     errorMessage="Invalid name" validate={{
                                required: {value: true,errorMessage: 'This Field is Required.',},
                                pattern: {value: '^[A-Za-z0-9]+$'},
                                minLength: {value: 2},
                                maxLength: {value: 32}
                            }}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Email</Label>
                            <AvInput onChange={handleChange} type="email" name="email" value={user.email} placeholder="User email" validate={{required: {value: true},email: true}}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Password</Label>
                            <AvInput onChange={handleChange}
                                     name="password"
                                     type="password"
                                     value={user.password}
                                     placeholder="Password"
                                     validate={{
                                         required: {value: true},
                                         minLength: {value: 8},
                                         maxLength: {value: 32}
                                     }}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Password confirm</Label>
                            <AvInput onChange={handleChange} type="password" name="passwordConfirm" value={user.passwordConfirm} placeholder="Confirm password"
                                     validate={{match:{value:'password'},required: {value: true}}}
                            />
                        </FormGroup>
                        <div className="text-center">
                            <Button color="primary">{props.match.params.id?'Save':'Add'}</Button>
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        </Container>
    )
};

export default NewUser;
