import React,{useEffect,useState,useRef} from "react"
import bgImage from "../../../../assets/blocks/header1/bg.jpeg"
import InlineEditableText from "../InlineEditableText"
import Switch from "react-switch"
import {Input} from "reactstrap"
import API from "../../../../utils/API"
import InputColor from 'react-input-color'
import Slider from 'react-input-slider'

const Button = (props) => {

    const [showOption, setShowOptions] = useState(false)
    const [data, setData] = useState((props.data)?props.data:props.defaultdata?props.defaultdata:{bgColor:"#ffffff",bgType:'color',bgbuttonColor:"#2693e6",bgbuttontype:"color",buttonWidth:30,buttonRadius:3,buttonBorder:false,buttonBorderColor:"#000000",buttonBorderWidth:1})

    const [clickedOutside, setClickedOutside] = useState(false);

    const ref = useRef();

    const handleClickOutside = e => {
        if (ref && ref.current && !ref.current.contains(e.target)) {
            setClickedOutside(true)
            setShowOptions(false)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    },[]);

    const setValue = (name,value) => {
        let newData = {
            ...data,
            [name]: value
        }

        setData(newData)
        props.setValue(newData)
    }

    let image = {
        backgroundRepeat: "no-repeat",
        backgroundPosition : "center",
        backgroundSize: "cover",
        backgroundImage: (!data.bgType || data.bgType === 'image')?`url(${data.bgImage?data.bgImage:bgImage})`:'',
        backgroundColor: (data.bgType && data.bgType === 'color')?(data.bgColor?data.bgColor:'#ffffff'):'',
        position:"relative",
        border:(props.isResult)?"dashed 1px #64afb9":"0",
        paddingTop: (data.paddingTop || data.paddingTop === 0)?data.paddingTop:0,
        paddingBottom: (data.paddingBottom || data.paddingBottom === 0)?data.paddingBottom:0,
    }

    let overlay = {
        background: data.overlayColor?data.overlayColor:"#000000",
        opacity: (data.opacity || data.opacity === 0)?data.opacity:0,
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0
    }

    const handleFileSelect = (event) => {
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = (e) => {
            API.post('/imageadmin',{image:e.target.result}).then((response) => {
                setValue('bgImage',response.data.data.url)
            });
        }
    }

    const handleFileSelectButton = (event) => {
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = (e) => {
            API.post('/imageadmin',{image:e.target.result}).then((response) => {
                setValue('bgbuttonImage',response.data.data.url)
            });
        }
    }

    return (
        <div style={{position:"relative"}} onClick={(e)=>{if (e.target.tagName.toLowerCase() === 'div'){
            if (props.isResult) {
                setShowOptions(true)
            }
        }}}>
            <div style={image} className="d-flex">
                <div style={overlay}/>
                <div className="w-100 text-white bTitleText">
                    <div className="row">
                        <div className="col text-center">
                            <button type="button" className="btn btn-mini text-left"
                                    style={{backgroundSize:(data.bgbuttontype === 'image' && data.bgbuttonImage)?"cover":"unset",backgroundPosition:(data.bgbuttontype === 'image' && data.bgbuttonImage)?"center":"unset",backgroundRepeat:(data.bgbuttontype === 'image' && data.bgbuttonImage)?"no-repeat":"unset",border:data.buttonBorder?("solid "+data.buttonBorderWidth+"px "+data.buttonBorderColor):"unset",borderRadius:data.buttonRadius+"px",backgroundColor:(data.bgbuttontype === 'color')?data.bgbuttonColor:"unset",backgroundImage:(data.bgbuttontype === 'image' && data.bgbuttonImage)?`url(${data.bgbuttonImage})`:"unset",width:data.buttonWidth+"%"}}>
                                <InlineEditableText
                                    isResult={props.isResult} name="button"
                                    setText={setValue}>{data.button ? data.button : 'Button'}
                                </InlineEditableText>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {showOption &&
                <div ref={ref} className="pb-5" style={{position:"absolute",left:"101%",backgroundColor:"black",top:0,zIndex:"1000",borderRadius:"5px",width:"250px",textAlign:"center",padding:"10px"}}>
                    <div className="row">
                        <div className="col">
                            <span className="text-white">Settings</span>
                        </div>
                    </div>
                    <span className="text-white">Button width</span>
                    <div className="row">
                        <div className="col-12 text-center">
                            <Slider
                                styles={{
                                    active: {
                                        backgroundColor: '#86d3ff'
                                    },
                                    thumb: {
                                        width: 20,
                                        height: 20,
                                        backgroundColor: '#2693e6'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                                xmax={95} xmin={10} xstep={1} axis="x" x={data.buttonWidth} onChange={({ x }) => setValue('buttonWidth',x)} />
                        </div>
                    </div>
                    <span className="text-white">Button height</span>
                    <div className="row">
                        <div className="col-12 text-center">
                            <Slider
                                styles={{
                                    active: {
                                        backgroundColor: '#86d3ff'
                                    },
                                    thumb: {
                                        width: 20,
                                        height: 20,
                                        backgroundColor: '#2693e6'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                                xmax={200} xmin={30} xstep={1} axis="x" x={data.buttonHeight} onChange={({ x }) => setValue('buttonHeight',x)} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Button border</span>
                        </div>
                        <div className="col-6 text-right">
                            <Switch
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                checked={data.buttonBorder}
                                onChange={(nextChecked)=>setValue('buttonBorder',nextChecked)}
                            />
                        </div>
                    </div>
                    {data.buttonBorder &&
                        <>
                            <div className="row">
                                <div className="col-auto">
                                    <InputColor
                                        initialValue={data.buttonBorderColor?data.buttonBorderColor:"#000000"}
                                        onChange={(color)=>setValue('buttonBorderColor',color.hex)}
                                        placement="right"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-left">
                                    <span className="text-white">Button border width</span>
                                </div>
                                <div className="col-12 text-center">
                                    <Slider
                                        styles={{
                                            active: {
                                                backgroundColor: '#86d3ff'
                                            },
                                            thumb: {
                                                width: 20,
                                                height: 20,
                                                backgroundColor: '#2693e6'
                                            },
                                            disabled: {
                                                opacity: 0.5
                                            }
                                        }}
                                        xmax={10} xmin={1} xstep={1} axis="x" x={data.buttonBorderWidth} onChange={({ x }) => setValue('buttonBorderWidth',x)} />
                                </div>
                            </div>
                        </>
                    }
                    <div className="row mt-3">
                        <div className="col-12 text-left">
                            <span className="text-white">Button border radius</span>
                        </div>
                        <div className="col-12 text-center">
                            <Slider
                                styles={{
                                    active: {
                                        backgroundColor: '#86d3ff'
                                    },
                                    thumb: {
                                        width: 20,
                                        height: 20,
                                        backgroundColor: '#2693e6'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                                xmax={20} xmin={0} xstep={1} axis="x" x={data.buttonRadius} onChange={({ x }) => setValue('buttonRadius',x)} />
                        </div>
                    </div>
                    <div className="text-white mt-3">Button background</div>
                    <div className="row mt-2">
                        <div className="col-6 text-left">
                            <span className="text-white">Image</span>
                        </div>
                        <div className="col-6 text-right">
                            <Input type="radio" style={{width:25,height:25}} name="bgbuttontype" checked={data.bgbuttontype==='image'} onChange={() => setValue('bgbuttontype','image')}/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-6 text-left">
                            <span className="text-white">Color</span>
                        </div>
                        <div className="col-6 text-right">
                            <Input type="radio" style={{width:25,height:25}} name="bgbuttontype" checked={data.bgbuttontype==='color'} onChange={() => setValue('bgbuttontype','color')} />
                        </div>
                    </div>
                    {data.bgbuttontype === 'image' &&
                        <>
                            <div className="row mt-3">
                                <div className="col text-left">
                                    {data.bgbuttonImage &&
                                        <img src={data.bgbuttonImage} className="img-fluid"/>
                                    }
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-6 text-left">
                                    <input type="file" onChange={handleFileSelectButton}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 text-left">
                                    <span className="text-white">Overlay</span>
                                </div>
                                <div className="col-6 text-right">
                                    <InputColor
                                        initialValue={data.bgbuttonoverlayColor?data.bgbuttonoverlayColor:"#000000"}
                                        onChange={(color)=>setValue('bgbuttonoverlayColor',color.hex)}
                                        placement="right"
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 text-left">
                                    <span className="text-white">Opacity</span>
                                </div>
                                <div className="col-12 text-center">
                                    <Slider
                                        styles={{
                                            active: {
                                                backgroundColor: '#86d3ff'
                                            },
                                            thumb: {
                                                width: 20,
                                                height: 20,
                                                backgroundColor: '#2693e6'
                                            },
                                            disabled: {
                                                opacity: 0.5
                                            }
                                        }}
                                        xmax={1} xmin={0} xstep={0.1} axis="x" x={(data.bgbuttonopacity || data.bgbuttonopacity === 0)?data.bgbuttonopacity:0} onChange={({ x }) => setValue('bgbuttonopacity',x)} />
                                </div>
                            </div>
                        </>
                    }
                    {data.bgbuttontype === 'color' &&
                        <>
                            <div className="row mt-3">
                                <div className="col text-left">
                                    <InputColor
                                        initialValue={data.bgbuttonColor?data.bgbuttonColor:"#000000"}
                                        onChange={(color)=>setValue('bgbuttonColor',color.hex)}
                                        placement="right"
                                    />
                                </div>
                            </div>

                        </>
                    }
                    <span className="text-white">Padding</span>
                    <div className="row mt-3">
                        <div className="col-12 text-left">
                            <span className="text-white">Top</span>
                        </div>
                        <div className="col-12 text-center">
                            <Slider
                                styles={{
                                    active: {
                                        backgroundColor: '#86d3ff'
                                    },
                                    thumb: {
                                        width: 20,
                                        height: 20,
                                        backgroundColor: '#2693e6'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                                xmax={50} xmin={0} xstep={1} axis="x" x={(data.paddingTop || data.paddingTop === 0)?data.paddingTop:0} onChange={({ x }) => setValue('paddingTop',x)} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 text-left">
                            <span className="text-white">Bottom</span>
                        </div>
                        <div className="col-12 text-center">
                            <Slider
                                styles={{
                                    active: {
                                        backgroundColor: '#86d3ff'
                                    },
                                    thumb: {
                                        width: 20,
                                        height: 20,
                                        backgroundColor: '#2693e6'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                                xmax={50} xmin={0} xstep={1} axis="x" x={(data.paddingBottom || data.paddingBottom === 0)?data.paddingBottom:0} onChange={({ x }) => setValue('paddingBottom',x)} />
                        </div>
                    </div>
                    <span className="text-white">Block background</span>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Image</span>
                        </div>
                        <div className="col-6 text-right">
                            <Input type="radio" style={{width:25,height:25}} name="bgtype" checked={data.bgType==='image'} onChange={() => setValue('bgType','image')}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Color</span>
                        </div>
                        <div className="col-6 text-right">
                            <Input type="radio" style={{width:25,height:25}} name="bgtype" checked={data.bgType==='color'} onChange={() => setValue('bgType','color')} />
                        </div>
                    </div>
                    {data.bgType === 'image' &&
                        <>
                            <div className="row mt-3">
                                <div className="col text-left">
                                    {data.bgImage &&
                                        <img src={data.bgImage} className="img-fluid"/>
                                    }
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-6 text-left">
                                    <input type="file" onChange={handleFileSelect}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 text-left">
                                    <span className="text-white">Overlay</span>
                                </div>
                                <div className="col-6 text-right">
                                    <InputColor
                                        initialValue={data.overlayColor?data.overlayColor:"#000000"}
                                        onChange={(color)=>setValue('overlayColor',color.hex)}
                                        placement="right"
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 text-left">
                                    <span className="text-white">Opacity</span>
                                </div>
                                <div className="col-12 text-center">
                                    <Slider
                                        styles={{
                                            active: {
                                                backgroundColor: '#86d3ff'
                                            },
                                            thumb: {
                                                width: 20,
                                                height: 20,
                                                backgroundColor: '#2693e6'
                                            },
                                            disabled: {
                                                opacity: 0.5
                                            }
                                        }}
                                        xmax={1} xmin={0} xstep={0.1} axis="x" x={(data.opacity || data.opacity === 0)?data.opacity:0} onChange={({ x }) => setValue('opacity',x)} />
                                </div>
                            </div>
                        </>
                    }
                    {data.bgType === 'color' &&
                        <>
                            <div className="row mt-3">
                                <div className="col text-left">
                                    <InputColor
                                        initialValue={data.bgColor?data.bgColor:"#000000"}
                                        onChange={(color)=>setValue('bgColor',color.hex)}
                                        placement="right"
                                    />
                                </div>
                            </div>

                        </>
                    }
                </div>
            }
        </div>
    )
}

export default Button
