import React,{useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

const FAQItem = ({data,setValue,deleteFAQElement}) => {
    const [currentData, setCurrentData] = useState(data)

    const changeValue = (name, value) => {
        let currentDataValues = currentData
        currentDataValues[name] = value
        setCurrentData(currentDataValues)
        setValue(currentDataValues.id,name,value)
    }

    return (
        <div>
            <hr style={{backgroundColor:"white",height:"1px",width:"100%"}}/>
            <div className="row">
                <div className="col text-right">
                    <FontAwesomeIcon icon={faTrash} color={"white"} className="cursor-pointer" onClick={(e)=>{deleteFAQElement(currentData.id)}}/>
                </div>
            </div>
            <div className="mt-3">
                <div className="text-white text-left mb-1">Question</div>
                <input type="text" value={currentData.question} className="form-control" onChange={(e)=>changeValue('question',e.target.value)}/>
            </div>
            <div className="mt-3">
                <div className="text-white text-left mb-1">Answer</div>
                <textarea className="form-control" onChange={(e)=>changeValue('answer',e.target.value)}>{currentData.answer}</textarea>
            </div>
        </div>
    )
}

export default FAQItem
