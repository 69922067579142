import React,{useState,useEffect} from "react";
import {Badge, CardBody, Container, Nav, NavItem, NavLink, TabContent, Table, TabPane} from "reactstrap";
import classnames from "classnames";
import API from "../../utils/API"
import Loader from "../../components/Loader";
import Logs from "./Logs";
import PreviewApp from "../../components/PreviewApp";
import BillingCustomer from "./BillingCustomer";
import PaymentMethod from "./PaymentMethod";
import Note from "./Note";
import {toastr} from "react-redux-toastr";
import {useHistory} from "react-router-dom";

const User = (props) => {
    const [activeTab,setActiveTab] = useState("summary")
    const [logsTab,setLogsTab] = useState("all")

    const [user,setUser] = useState(null)
    const [apps,setApps] = useState(null)

    let history = useHistory();

    const loadUser = () => {
        API.get('/admin/users/item/'+props.match.params.id).then((response) => {
            setUser(response.data.data)
        });
    }

    useEffect(() => {
        loadUser();

        API.post('/admin/apps',{user:props.match.params.id}).then((response) => {
            setApps(response.data.data)
        });
    }, [props.match.params.id])

    const oneClickLogIn = () => {
        API.get('/admin/users/oneclicklogin/'+props.match.params.id).then((response) => {
            window.open(response.data.data.url);
        });
    }

    const deactivate = () => {
        API.post('/admin/users/status/'+user.id,{status:0}).then((response) => {
            loadUser();
            toastr.success('Success', 'User was successfully deactivated');
        });
    }

    const activate = () => {
        API.post('/admin/users/status/'+user.id,{status:1}).then((response) => {
            loadUser();
            toastr.success('Success', 'User was successfully activated');
        });
    }

    return (
        <Container fluid className="p-0">
            {(user && apps) &&
                <>
                    <h1 className="h3 mb-3">{user.name}</h1>
                    <div className={"tab default"}>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === "summary" })}
                                    onClick={() => {
                                        setActiveTab("summary");
                                    }}
                                >
                                    Summary
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === "apps" })}
                                    onClick={() => {
                                        setActiveTab("apps");
                                    }}
                                >
                                    Applications {(user.apps > 0) && <Badge color="success">{user.apps}</Badge>}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === "logs" })}
                                    onClick={() => {
                                        setActiveTab("logs");
                                    }}
                                >
                                    Logs
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="summary">
                                <div className="row">
                                    <div className="col-3">
                                        <ul className="list-unstyled mb-0">
                                            <li className="mt-1">Name: <strong>{user.name}</strong></li>
                                            <li className="mt-1">Status:
                                                {user.status === 1 ?
                                                    <>
                                                        <Badge color="success">Active</Badge>
                                                        <button className="btn btn-danger ml-3 btn-sm" onClick={()=>deactivate()}>Deactivate</button>
                                                    </>
                                                    :
                                                    <>
                                                        <Badge color="danger">Not active</Badge>
                                                        <button className="btn btn-success ml-3 btn-sm" onClick={()=>activate()}>Activate</button>
                                                    </>
                                                }
                                            </li>
                                            <li className="mt-1">Date add: <strong>{user.dateadd}</strong></li>
                                            <li className="mt-1">Email: <strong>{user.email}</strong></li>
                                            <li className="mt-1">Active subscriptions: <strong>{user.activeSubscriptions}</strong></li>
                                            <li className="mt-1">Lifetime revenue: <strong>{user.revenue}</strong></li>
                                        </ul>
                                        <button className="m-2 btn btn-primary" onClick={oneClickLogIn}>Sign In as user</button>
                                        <button className="m-2 btn btn-secondary" onClick={()=>history.push(`/user/password/${user.id}`)}>Change password</button>
                                    </div>
                                    <div className="col-3">
                                        <BillingCustomer id={user.id}/>
                                    </div>
                                    <div className="col-6">
                                        <PaymentMethod id={user.id}/>
                                    </div>
                                </div>
                                <Note userId={user.id}/>
                            </TabPane>
                            <TabPane tabId="apps">
                                {apps.length > 0 &&
                                    <div className="table-responsive">
                                        <Table>
                                            <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Date add</th>
                                                <th>Subscription</th>
                                                <th>Setup payment</th>
                                                <th>Preview</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                apps.map((app, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{app.app.title}</td>
                                                            <td>{app.app.dateadd}</td>
                                                            <td>{app.billingproduct?.title?<Badge color="success" className="mr-1 mb-1">{app.billingproduct?.title}</Badge>:''}</td>
                                                            <td>{app.billingsubmissionpayment?.paymentdate?<Badge color="primary" className="mr-1 mb-1">{app.billingsubmissionpayment?.paymentdate}</Badge>:''}</td>
                                                            <td><PreviewApp id={app.app.id}/></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </Table>
                                    </div>
                                }
                            </TabPane>
                            <TabPane tabId="logs">
                                <div className={"tab default"}>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ subactive: logsTab === "all" })}
                                                onClick={() => {
                                                    setLogsTab("all");
                                                }}
                                            >
                                                All
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ subactive: logsTab === "app" })}
                                                onClick={() => {
                                                    setLogsTab("app");
                                                }}
                                            >
                                                App
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ subactive: logsTab === "account" })}
                                                onClick={() => {
                                                    setLogsTab("account");
                                                }}
                                            >
                                                Account
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ subactive: logsTab === "billing" })}
                                                onClick={() => {
                                                    setLogsTab("billing");
                                                }}
                                            >
                                                Billing
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={logsTab}>
                                        <TabPane tabId="all">
                                            <Logs user={user.id}/>
                                        </TabPane>
                                        <TabPane tabId="app">
                                            <Logs user={user.id} type={"App"}/>
                                        </TabPane>
                                        <TabPane tabId="account">
                                            <Logs user={user.id} type={"Account"}/>
                                        </TabPane>
                                        <TabPane tabId="billing">
                                            <Logs user={user.id} type={"Billing"}/>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </TabPane>
                        </TabContent>
                    </div>
                </>
                }
        </Container>
    )
}

export default User;
