import React,{useEffect,useState,useRef} from "react"
import InlineEditableText from "../InlineEditableText";
import Switch from "react-switch";
import InputColor from "react-input-color";
import Slider from "react-input-slider";
import { v4 as uuidv4 } from 'uuid';
import SlideshowItem from "./SlideshowItem";
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';

const Slideshow = (props) => {
    const [data,setData] = useState((props.data)?props.data:props.defaultdata?props.defaultdata:{titleActive:true})

    const [showOption, setShowOptions] = useState(false)

    const [clickedOutside, setClickedOutside] = useState(false);

    const ref = useRef();

    const handleClickOutside = e => {
        if (ref && ref.current && !ref.current.contains(e.target)) {
            setClickedOutside(true)
            setShowOptions(false)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    },[]);

    const setImageData = (id,image) => {
        let images = data?.images??[]
        let n = images?.length??0
        let i, set = false
        for (i=0; i < n; i++) {
            if (images[i]?.id === id) {
                images[i].data = image
                set = true
            }
        }

        if (!set) {
            images.push({
                id:id,
                data:image
            })
        }
        setValue('images',images)
    }

    const deleteImage = (id) => {
        let images = data?.images??[]
        let newImages = [], i, n = images?.length??0
        for (i=0; i < n; i++) {
            if (images[i]?.id !== id) {
                newImages.push(images[i])
            }
        }

        setValue('images',newImages)
    }

    const setValue = (name,value) => {
        let newData = {
            ...data,
            [name]: value
        }

        setData(newData)
        props.setValue(newData)
    }

    let mainStyle = {
        backgroundColor: data?.bgColor??'#FFFFFF',
        border:(props.isResult)?"dashed 1px #64afb9":"0",
        paddingTop: (data?.paddingTop || data?.paddingTop === 0)?data.paddingTop:0,
        paddingBottom: (data?.paddingBottom || data?.paddingBottom === 0)?data.paddingBottom:0,
    }

    const newImage = () => {
        setImageData(uuidv4(),{image:'https://gettow.s3.amazonaws.com/image/61e670c73d5e4.jpeg'})
    }

    return (
        <div style={{position:"relative"}} onClick={(e)=>{if (e.target.tagName.toLowerCase() === 'div'){
            if (props.isResult) {
                setShowOptions(true)
            }
        }}}>
            <div style={mainStyle} className="d-flex">
                <div className="w-100 text-white bTitleText">
                    {data?.titleActive &&
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <InlineEditableText isResult={props.isResult} name="title"
                                                setText={setValue}>{data?.title??'<h1 class="ql-align-center"><span style="color: rgb(0, 0, 255);">Slideshow</span></h1>'}</InlineEditableText>
                        </div>
                    </div>
                    }
                    {data?.subtitleActive &&
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <InlineEditableText isResult={props.isResult} name="subtitle"
                                                setText={setValue}>{data?.subtitle??'<p class="ql-align-center"><span style="color: rgb(0, 0, 0);">Sub title</span></p>'}</InlineEditableText>
                        </div>
                    </div>
                    }
                    {data?.images?.length > 0 &&
                    <div className="row justify-content-center">
                        <div className="col">
                    <Slide autoplay={data?.autoPlay}>
                        {data.images.map((image,id)=>{
                            return (<div className="each-slide" key={image.data.id}>
                                <div style={{'backgroundImage': `url(${image.data.image})`}}></div>
                            </div>)
                        })}
                    </Slide>
                        </div>
                    </div>
                    }
                    {data?.textActive &&
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <InlineEditableText
                                isResult={props.isResult}
                                name="text"
                                setText={setValue}
                            >
                                {data.text??'<p class="ql-align-center"><span style="color: rgb(0, 0, 0);">Text</span></p>'}
                            </InlineEditableText>
                        </div>
                    </div>
                    }
                </div>
            </div>
            {showOption &&
            <div ref={ref} className="pb-5" style={{position:"absolute",left:"101%",backgroundColor:"black",top:0,zIndex:"1000",borderRadius:"5px",width:"250px",textAlign:"center",padding:"10px"}}>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Title</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            checked={data?.titleActive}
                            onChange={(nextChecked)=>setValue('titleActive',nextChecked)}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Subtitle</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                checked={data?.subtitleActive} onChange={(nextChecked)=>setValue('subtitleActive',nextChecked)}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Text</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                checked={data?.textActive} onChange={(nextChecked)=>setValue('textActive',nextChecked)}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Auto play</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                checked={data?.autoPlay} onChange={(nextChecked)=>setValue('autoPlay',nextChecked)}/>
                    </div>
                </div>
                <span className="text-white">Padding</span>
                <div className="row mt-1">
                    <div className="col-12 text-left">
                        <span className="text-white">Top</span>
                    </div>
                    <div className="col-12 text-center">
                        <Slider
                            styles={{
                                active: {
                                    backgroundColor: '#86d3ff'
                                },
                                thumb: {
                                    width: 20,
                                    height: 20,
                                    backgroundColor: '#2693e6'
                                },
                                disabled: {
                                    opacity: 0.5
                                }
                            }}
                            xmax={50} xmin={0} xstep={1} axis="x" x={(data?.paddingTop || data?.paddingTop === 0)?data.paddingTop:0} onChange={({ x }) => setValue('paddingTop',x)} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 text-left">
                        <span className="text-white">Bottom</span>
                    </div>
                    <div className="col-12 text-center">
                        <Slider
                            styles={{
                                active: {
                                    backgroundColor: '#86d3ff'
                                },
                                thumb: {
                                    width: 20,
                                    height: 20,
                                    backgroundColor: '#2693e6'
                                },
                                disabled: {
                                    opacity: 0.5
                                }
                            }}
                            xmax={50} xmin={0} xstep={1} axis="x" x={(data?.paddingBottom || data?.paddingBottom === 0)?data.paddingBottom:0} onChange={({ x }) => setValue('paddingBottom',x)} />
                    </div>
                </div>
                <div className="text-white text-left">Background</div>
                <div className="row mt-3">
                    <div className="col text-left">
                        <InputColor
                            initialValue={data?.bgColor??"#ffffff"}
                            onChange={(color)=>setValue('bgColor',color.hex)}
                            placement="right"
                        />
                    </div>
                </div>
                <span className="text-white">Images</span>
                <div className="text-center mt-2">
                    <button className="btn btn-outline-primary" onClick={()=>newImage()}>Add image</button>
                </div>
                {data?.images?.map((image,id)=>{
                    return <div key={"imageItem"+id}><SlideshowItem data={image} setImageData={setImageData} deleteImage={deleteImage}/></div>
                })}
            </div>
            }
        </div>
    )
}

export default Slideshow
