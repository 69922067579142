import axios from "axios";

const API = axios.create({
    baseURL: process.env.REACT_APP_API,
    timeout: 60000,
});

API.interceptors.request.use(
    (config) => {
        let jwt = localStorage.getItem('jwt')
        if (jwt) {
            config.headers.Authorization = `Bearer ${jwt}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default API;
