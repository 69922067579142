import React,{useEffect,useState,useRef} from "react"
import InlineEditableText from "../InlineEditableText"
import Switch from "react-switch"
import API from "../../../../utils/API"
import InputColor from 'react-input-color'
import Slider from 'react-input-slider'

const Intro = (props) => {

    const [showOption, setShowOptions] = useState(false)
    const [data, setData] = useState((props.data)?props.data:props.defaultdata?props.defaultdata:{titleActive:true,subtitleActive:true,textActive:true,imageSize:80,image:'https://gettow.s3.amazonaws.com/image/61c16e45f1bb6.jpeg'})

    const [clickedOutside, setClickedOutside] = useState(false);

    const ref = useRef();

    const handleClickOutside = e => {
        if (ref && ref.current && !ref.current.contains(e.target)) {
            setClickedOutside(true)
            setShowOptions(false)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    },[]);

    const setValue = (name,value) => {
        let newData = {
            ...data,
            [name]: value
        }

        setData(newData)
        props.setValue(newData)
    }

    let mainStyle = {
        backgroundColor: data.bgColor?data.bgColor:'#FFFFFF',
        border:(props.isResult)?"dashed 1px #64afb9":"0",
        paddingTop: (data.paddingTop || data.paddingTop === 0)?data.paddingTop:0,
        paddingBottom: (data.paddingBottom || data.paddingBottom === 0)?data.paddingBottom:0,
    }

    const handleFileSelect = (event) => {
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = (e) => {
            API.post('/imageadmin',{image:e.target.result}).then((response) => {
                setValue('image',response.data.data.url)
            });
        }
    }

    return (
        <div style={{position:"relative"}} onClick={(e)=>{if (e.target.tagName.toLowerCase() === 'div'){
            if (props.isResult) {
                setShowOptions(true)
            }
        }}}>
            <div style={mainStyle} className="d-flex">
                <div className="w-100 text-white bTitleText pt-1 pb-1">
                    {data.titleActive &&
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <InlineEditableText isResult={props.isResult} name="title"
                                                setText={setValue}>{data.title ? data.title : '<h1 class="ql-align-center"><span style="color: rgb(0, 0, 255);">View in action</span></h1>'}</InlineEditableText>
                        </div>
                    </div>
                    }
                    {data.subtitleActive &&
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <InlineEditableText isResult={props.isResult} name="subtitle"
                                                setText={setValue}>{data.subtitle ? data.subtitle : '<p class="ql-align-center"><span style="color: rgb(0, 0, 0);">Sub title</span></p>'}</InlineEditableText>
                        </div>
                    </div>
                    }
                    {data.textActive &&
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <InlineEditableText
                                isResult={props.isResult}
                                name="text"
                                setText={setValue}
                            >
                                {data.text ? data.text : '<p class="ql-align-center"><span style="color: rgb(0, 0, 0);">Text</span></p>'}
                            </InlineEditableText>
                        </div>
                    </div>
                    }
                    {data.buttonactive &&
                    <div className="row justify-content-center mt-3">
                        <div className="col-auto">
                            <button type="button" className="btn btn-mini" style={{backgroundColor:data.buttonColor?data.buttonColor:"#2693e6"}}><InlineEditableText
                                isResult={props.isResult} name="button"
                                setText={setValue}>{data.button ? data.button : 'Button'}</InlineEditableText></button>
                        </div>
                    </div>
                    }
                    {data.image &&
                    <div className="row justify-content-center mt-3">
                        <div className="col text-center">
                            <img src={data.image} className="img-fluid" style={{width:data.imageSize+"%"}}/>
                        </div>
                    </div>
                    }
                </div>
            </div>
            {showOption &&
            <div ref={ref} className="pb-5" style={{position:"absolute",left:"101%",backgroundColor:"black",top:0,zIndex:"1000",borderRadius:"5px",width:"250px",textAlign:"center",padding:"10px"}}>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Title</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            checked={data.titleActive}
                            onChange={(nextChecked)=>setValue('titleActive',nextChecked)}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Subtitle</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                checked={data.subtitleActive} onChange={(nextChecked)=>setValue('subtitleActive',nextChecked)}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Text</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                checked={data.textActive} onChange={(nextChecked)=>setValue('textActive',nextChecked)}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col text-left">
                        <span className="text-white">Button</span>
                    </div>
                    {data.buttonactive &&
                        <div className="col-auto">
                            <InputColor
                                initialValue={data.buttonColor?data.buttonColor:"#2693e6"}
                                onChange={(color)=>setValue('buttonColor',color.hex)}
                                placement="right"
                            />
                        </div>
                    }
                    <div className="col-auto text-right">
                        <Switch
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            checked={data.buttonactive} onChange={(nextChecked)=>setValue('buttonactive',nextChecked)}/>
                    </div>
                </div>
                <span className="text-white">Padding</span>
                <div className="row mt-3">
                    <div className="col-12 text-left">
                        <span className="text-white">Top</span>
                    </div>
                    <div className="col-12 text-center">
                        <Slider
                            styles={{
                                active: {
                                    backgroundColor: '#86d3ff'
                                },
                                thumb: {
                                    width: 20,
                                    height: 20,
                                    backgroundColor: '#2693e6'
                                },
                                disabled: {
                                    opacity: 0.5
                                }
                            }}
                            xmax={50} xmin={0} xstep={1} axis="x" x={(data.paddingTop || data.paddingTop === 0)?data.paddingTop:0} onChange={({ x }) => setValue('paddingTop',x)} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 text-left">
                        <span className="text-white">Bottom</span>
                    </div>
                    <div className="col-12 text-center">
                        <Slider
                            styles={{
                                active: {
                                    backgroundColor: '#86d3ff'
                                },
                                thumb: {
                                    width: 20,
                                    height: 20,
                                    backgroundColor: '#2693e6'
                                },
                                disabled: {
                                    opacity: 0.5
                                }
                            }}
                            xmax={50} xmin={0} xstep={1} axis="x" x={(data.paddingBottom || data.paddingBottom === 0)?data.paddingBottom:0} onChange={({ x }) => setValue('paddingBottom',x)} />
                    </div>
                </div>
                <div className="text-white text-left">Background</div>
                <div className="row mt-3">
                    <div className="col text-left">
                        <InputColor
                            initialValue={data.bgColor?data.bgColor:"#ffffff"}
                            onChange={(color)=>setValue('bgColor',color.hex)}
                            placement="right"
                        />
                    </div>
                </div>
                <span className="text-white">Image</span>
                <div className="row mt-3">
                    <div className="col text-left">
                        {data.image &&
                            <img src={data.image} className="img-fluid"/>
                        }
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-6 text-left">
                        <input type="file" onChange={handleFileSelect}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 text-left">
                        <span className="text-white">Image size</span>
                    </div>
                    <div className="col-12 text-center">
                        <Slider
                            styles={{
                                active: {
                                    backgroundColor: '#86d3ff'
                                },
                                thumb: {
                                    width: 20,
                                    height: 20,
                                    backgroundColor: '#2693e6'
                                },
                                disabled: {
                                    opacity: 0.5
                                }
                            }}
                            xmax={100} xmin={0} xstep={1} axis="x" x={(data.imageSize || data.imageSize === 0)?data.imageSize:100} onChange={({ x }) => setValue('imageSize',x)} />
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default Intro
