import React,{useState,useEffect} from "react";
import API from "../../utils/API"
import NewPaymentMethod from "./NewPaymentMethod";

const PaymentMethod = ({id}) => {
    const [data,setData] = useState(null)

    const [newPaymentMethod,setNewPaymentMethod] = useState(false)

    useEffect(() => {
        API.get('/admin/users/paymentmethod/'+id).then((response) => {
            setData(response.data.data)
        });
    }, [id,newPaymentMethod])

    return (
        <>
            {newPaymentMethod ?
                <NewPaymentMethod userId={id} onFinish={()=>setNewPaymentMethod(false)}/>
                :
                <>
                    <div className="mb-2"><button className="btn btn-success" onClick={()=>setNewPaymentMethod(true)}>Add payment method</button></div>
                    {data &&
                        <>
                            <strong>Payment method</strong>
                            <div>
                                Last 4 numbers: {data.cardnumber}<br/>
                                Type: {data.cardtype}<br/>
                                Name on card: {data.cardname}<br/>
                                Expiration: {data.cardexpiration}<br/>
                                Date add: {data.dateadd}
                            </div>
                        </>

                    }
                </>
            }
        </>
    )
}

export default PaymentMethod;
