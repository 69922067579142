import React, {useState} from "react"
import API from "../../utils/API"
import {Button, Card, CardBody, Container, FormGroup, Label} from "reactstrap";
import {AvForm, AvInput} from "availity-reactstrap-validation";
import {toastr} from "react-redux-toastr";
import {useHistory} from "react-router-dom"

const Newtemplate = () => {
    const [title,setTitle] = useState(null)

    let history = useHistory()

    const submit = () => {
        API.post('/admin/apptemplate',{title:title})
            .then((response) => {
                toastr.success('Success', 'App was successfully added')
                history.push("/templates/configure/"+response.data.data.id)
            })
    }

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">New app</h1>
            <Card>
                <CardBody>
                    <AvForm onValidSubmit={submit}>
                        <FormGroup>
                            <Label>Title</Label>
                            <AvInput onChange={(e)=>setTitle(e.target.value)} required type="text" name="title" placeholder="Page title" />
                        </FormGroup>
                        <div className="text-center">
                            <Button color="primary">Save</Button>
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        </Container>
    )
}

export default Newtemplate
