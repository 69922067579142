import React,{useState,useEffect} from "react"
import {
    Badge, Button,
    Card, CardBody,
    Col,
    Container,
    Row,
    Table
} from "reactstrap";
import Loader from "../../components/Loader";
import SubmissionStatus from "../../components/formfields/SubmissionStatus";
import API from "../../utils/API";
import SubmissionStatusBadge from "../../components/status/SubmissionStatusBadge";
import { useHistory } from "react-router-dom";

const Submissions = () => {

    let history = useHistory()

    const defaultStatus = [
        {id:1,label:"New"},
        {id:2,label:"In work"}
    ]

    const [filtration,setFiltration] = useState({status:[1,2]})
    const [submissions, setSubmissions] = useState(null)
    const [loading, setLoading] = useState(true)

    const changeData = (name,value) => {
        setFiltration({ ...filtration, [name]: value })
    }

    useEffect(() => {
        setLoading(true)
        API.post('/admin/appsubmit/list',filtration).then((response) => {
            setSubmissions(response.data.data)
            setLoading(false)
        });
    }, [filtration])

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">App submissions</h1>
            <Row form>
                <Col md={4}>
                    <SubmissionStatus
                        name={"status"}
                        onChange={changeData}
                        defaultValue={defaultStatus}
                    />
                </Col>
            </Row>
            <Card className="mt-2">
                {loading &&
                    <Loader absolute={true}/>
                }
                {submissions?.length > 0 ?
                    <div className="table-responsive">
                        <Table>
                            <thead>
                            <tr>
                                <th>App</th>
                                <th>Submission status</th>
                                <th>Submission date</th>
                                <th>Platforms</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                submissions.map((submission, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{submission.app.title}</td>
                                            <td><SubmissionStatusBadge status={submission.appsubmit.status}/></td>
                                            <td>{submission.appsubmit.submitdate}</td>
                                            <td>
                                                {
                                                    submission.appsubmit.data.platform.map((platform,key) => {
                                                        return (
                                                            <Badge color="secondary" className="mr-1 mb-1" key={key}>
                                                                {platform}
                                                            </Badge>
                                                        )
                                                    })
                                                }
                                            </td>
                                            <td className={"text-right"}><Button color={"primary"} onClick={()=>history.push('/submissions/manage/'+submission.appsubmit.id)}>Manage</Button></td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    </div>
                    :
                    !loading &&
                    <CardBody className="text-center"><h5>Data not found</h5></CardBody>
                }
            </Card>
        </Container>
    )
}

export default Submissions
