import React,{useState,useEffect} from "react";
import API from "../../utils/API";
import Select from "react-select";

const SubmissionStatus = ({name,value,onChange,defaultValue}) => {
    const [source,setSource] = useState(null)

    const changeSelect = (value) => {
        let i,values = []
        for (i=0;i<value?.length;i++) {
            values.push(value[i].value)
        }
        if (name) {
            onChange(name,values)
        }
    }

    useEffect(() => {
        API.get('/admin/system/submissionstatuses').then((response) => {
            let options,i,optionsList = []
            if (response.data.data) {
                options = response.data.data
                for (i=0;i<options.length;i++) {
                    optionsList.push({value:options[i].id,label:options[i].title})
                }
            }
            setSource(optionsList)
        });
    }, [])

    return (
        <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={source}
            value={value}
            onChange={(value)=>changeSelect(value)}
            defaultValue={defaultValue}
            isMulti
            placeholder="Status"
        />
    )

}

export default SubmissionStatus;
