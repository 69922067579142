import async from "../components/Async";

import {
  Users as UsersIcon, Folder, Settings
} from "react-feather";

// Auth
import SignIn from "../pages/auth/SignIn";
import ResetPassword from "../pages/auth/ResetPassword";
import Admins from "../pages/users/Admins";
import Users from "../pages/users/Users";
import Admin from "../pages/users/Admin";
import Blocks from "../page/block/Blocks";
import BlockEdit from "../page/block/BlockEdit";
import Categories from "../page/block/Categories";
import CategoryEdit from "../page/block/CategoryEdit";
import Templates from "../page/templates/Templates";
import Newtemplate from "../page/templates/Newtemplate";
import Apps from "../pages/apps/Apps";
import User from "../pages/users/User";
import Submissions from "../pages/submissions/Submissions";
import Submission from "../pages/submissions/Submission";
import Faqs from "../page/block/Faqs";
import Faq from "../page/block/Faq";
import BlockSettings from "../page/block/BlockSettings";
import TemplatesList from "../page/templates/TemplatesList";
import EditTemplate from "../page/templates/EditTemplate";
import TemplateCategories from "../page/templates/TemplateCategories";
import TemplateCategoryEdit from "../page/templates/TemplateCategoryEdit";
import PaymentsDetails from "../pages/billing/PaymentsDetails";
import PaymentsSummary from "../pages/billing/PaymentsSummary";
import NewUser from "../pages/users/NewUser";
import ChangePassword from "../pages/users/ChangePassword";
import SubscriptionPlans from "../page/block/SubscriptionPlans";
import SubscriptionPlan from "../page/block/SubscriptionPlan";

const Appbuilder = async(() => import("../pages/dashboards/Appbuilder"));

const dashboardRoutes = {
  path: "/",
  name: "Dashboard",
  component: Appbuilder,
  header: null,
  privateRouter: true,
  children: null
};

const usermanagementRoutes = {
  name: "User management",
  path: "/user",
  icon: Folder,
  privateRouter: true,
  open:false,
  children: [
    {
      path: "/user",
      name: "Users",
      privateRouter: true,
      component: Users
    },
    {
      path: "/user/admins",
      name: "Admins",
      privateRouter: true,
      component: Admins
    },
    {
      path: "/user/admins/item/:id?",
      name: "New admin",
      privateRouter: true,
      component: Admin,
      hideInMenu: true
    },
    {
      path: "/user/item/:id?",
      name: "User",
      privateRouter: true,
      component: User,
      hideInMenu: true
    },
    {
      path: "/user/form/:id?",
      name: "User",
      privateRouter: true,
      component: NewUser,
      hideInMenu: true
    }
    ,
    {
      path: "/user/password/:id",
      name: "User",
      privateRouter: true,
      component: ChangePassword,
      hideInMenu: true
    }
  ]
};

const submissionManagementRoutes = {
  name: "App submissions",
  path: "/submissions",
  icon: Folder,
  privateRouter: true,
  open:false,
  children: [
    {
      path: "/submissions/new",
      name: "Pending",
      privateRouter: true,
      component: Submissions
    },
    {
      path: "/submissions/manage/:id?",
      name: "Submission",
      privateRouter: true,
      component: Submission,
      hideInMenu: true
    }
  ]
}

const billingRoutes = {
  name: "Billing report",
  path: "/billing",
  icon: Folder,
  privateRouter: true,
  open:false,
  children: [
    {
      path: "/billing/transacrions",
      name: "Transactions details",
      privateRouter: true,
      component: PaymentsDetails
    },
    {
      path: "/billing/transacrionssummary",
      name: "Transactions summary",
      privateRouter: true,
      component: PaymentsSummary
    }
  ]
};

const appmanagementRoutes = {
  name: "Application",
  path: "/apps",
  icon: Folder,
  privateRouter: true,
  open:false,
  children: [
    {
      path: "/apps/all",
      name: "All",
      privateRouter: true,
      component: Apps
    }
  ]
};

const blockmanagementRoutes = {
  name: "Modules",
  path: "/blocks",
  icon: Folder,
  privateRouter: true,
  open:false,
  children: [
    {
      path: "/blocks/list",
      name: "Modules",
      privateRouter: true,
      component: Blocks
    },
    {
      path: "/blocks/item/:id?",
      name: "Block",
      privateRouter: true,
      component: BlockEdit,
      hideInMenu: true
    },
    {
      path: "/blocks/settings/:id",
      name: "Block",
      privateRouter: true,
      component: BlockSettings,
      hideInMenu: true
    },
    {
      path: "/blocks/categories",
      name: "Categories",
      privateRouter: true,
      component: Categories
    },
    {
      path: "/blocks/category/:id?",
      name: "Category",
      privateRouter: true,
      component: CategoryEdit,
      hideInMenu: true
    },
  ]
};

const templatesRoutes = {
  name: "App templates",
  path: "/templates",
  icon: Folder,
  privateRouter: true,
  open:false,
  children: [
    {
      path: "/templates/list",
      name: "Templates",
      privateRouter: true,
      component: TemplatesList
    },
    {
      path: "/templates/configure/:id",
      privateRouter: true,
      component: Templates,
      hideInMenu: true
    },
    {
      path: "/templates/edit/:id",
      privateRouter: true,
      component: EditTemplate,
      hideInMenu: true
    },
    {
      path: "/templates/newtemplate",
      name: "New template",
      privateRouter: true,
      component: Newtemplate,
      hideInMenu: true
    },
    {
      path: "/templates/categories",
      name: "Categories",
      privateRouter: true,
      component: TemplateCategories
    },
    {
      path: "/templates/category/:id?",
      name: "Category",
      privateRouter: true,
      component: TemplateCategoryEdit,
      hideInMenu: true
    },
  ]
}

const settingsRoutes = {
  name: "Settings",
  path: "/settings",
  icon: Settings,
  privateRouter: true,
  open:false,
  children: [
    {
      path: "/settings/subscriptioplans",
      name: "Subscription plans",
      privateRouter: true,
      component: SubscriptionPlans
    },
    {
      path: "/settings/subscriptioplan/:id",
      name: "Subscription plan",
      privateRouter: true,
      component: SubscriptionPlan,
      hideInMenu: true
    },
    {
      path: "/settings/faqs",
      name: "FAQ",
      privateRouter: true,
      component: Faqs
    },
    {
      path: "/settings/faqs/item/:id?",
      name: "FAQ",
      privateRouter: true,
      component: Faq,
      hideInMenu: true
    },
  ]
};

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "Special",
  privateRouter: false,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      privateRouter: false,
      component: SignIn
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      privateRouter: false,
      component: ResetPassword
    }
  ]
};

// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  usermanagementRoutes,
  billingRoutes,
  appmanagementRoutes,
  blockmanagementRoutes,
  submissionManagementRoutes,
  templatesRoutes,
  settingsRoutes
];

// Auth specific routes
export const page = [authRoutes];

// All routes
export default [
  dashboardRoutes,
  usermanagementRoutes,
  billingRoutes,
  appmanagementRoutes,
  blockmanagementRoutes,
  submissionManagementRoutes,
  templatesRoutes,
  settingsRoutes
];
