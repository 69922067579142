import * as types from "../constants";

const initialState = {
    user: localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):{name:null,email:null,token:null,role:null},
    sign: localStorage.getItem('user')?true:false
};

export default function reducer(state = initialState, actions) {
    switch (actions.type) {
        case types.USER_LOGIN:
            localStorage.setItem('jwt',actions.payload.token)
            localStorage.setItem('user',JSON.stringify(actions.payload))
            return {
                ...state,
                user: actions.payload,
                sign: true
            };
        case types.USER_LOGOUT:
            localStorage.removeItem('jwt')
            localStorage.removeItem('user')
            return {
                ...state,
                user: null,
                sign: false
            };
        default:
            return state;
    }
}
