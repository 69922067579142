import React from "react";
import {Badge} from 'reactstrap';

const SubmissionStatusBadge = ({ status}) => {
    if (status == '1') {
        return <Badge color="secondary" className="mr-1 mb-1">
            New
        </Badge>
    }else if (status == '2') {
        return <Badge color="primary" className="mr-1 mb-1">
            In work
        </Badge>
    }else if (status == '3') {
        return <Badge color="success" className="mr-1 mb-1">
            Completed
        </Badge>
    }else if (status == '4') {
        return <Badge color="danger" className="mr-1 mb-1">
            Need more info
        </Badge>
    }
};

export default SubmissionStatusBadge;
