import React,{useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

const MenuCategoryItem = ({data,setValue,deleteMenuCategoryElement}) => {
    const [currentData, setCurrentData] = useState(data)

    const changeValue = (name, value) => {
        let currentDataValues = currentData
        currentDataValues[name] = value
        setCurrentData(currentDataValues)
        setValue(currentDataValues.id,name,value)
    }

    return (
        <div>
            <hr style={{backgroundColor:"white",height:"1px",width:"100%"}}/>
            <div className="row">
                <div className="col-auto">
                    <span className="text-white font-size-lg">{currentData.name}</span>
                </div>
                <div className="col text-right">
                    <FontAwesomeIcon icon={faTrash} color={"white"} className="cursor-pointer" onClick={(e)=>{deleteMenuCategoryElement(currentData.id)}}/>
                </div>
            </div>
            <div className="mt-3">
                <div className="text-white text-left mb-1">Name</div>
                <input type="text" value={currentData.name} className="form-control" onChange={(e)=>changeValue('name',e.target.value)}/>
            </div>
        </div>
    )
}

export default MenuCategoryItem
