import React,{useEffect,useState,useRef} from "react"
import InputColor from 'react-input-color'
import Slider from 'react-input-slider'
import { FacebookProvider, Page } from 'react-facebook';

const Facebook = (props) => {

    const [showOption, setShowOptions] = useState(false)
    const [data, setData] = useState(props.data?props.data:props.defaultdata?props.defaultdata:{paddingTop:10,paddingBottom:10})

    const ref = useRef();

    const handleClickOutside = e => {
        if (ref && ref.current && !ref.current.contains(e.target)) {
            setShowOptions(false)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    },[]);

    const setValue = (name,value) => {
        let newData = {
            ...data,
            [name]: value
        }

        setData(newData)
        props.setValue(newData)
    }

    let image = {
        backgroundRepeat: "no-repeat",
        backgroundPosition : "center",
        backgroundSize: "cover",
        backgroundColor: data.bgColor?data.bgColor:'#000000',
        position:"relative",
        border:(props.isResult)?"dashed 1px #64afb9":"0",
        paddingTop: (data.paddingTop || data.paddingTop === 0)?data.paddingTop:0,
        paddingBottom: (data.paddingBottom || data.paddingBottom === 0)?data.paddingBottom:0,
        paddingLeft: (data.paddingLeft || data.paddingLeft === 0)?data.paddingLeft:0,
        paddingRight: (data.paddingRight || data.paddingRight === 0)?data.paddingRight:0,
    }

    return (
        <div style={{position:"relative"}} onClick={(e)=>{if (e.target.tagName.toLowerCase() === 'div'){
            if (props.isResult) {
                setShowOptions(true)
            }
        }}}>
            <div style={image} className="d-flex">
                <div className="w-100 text-white bTitleText">
                    <div className="row justify-content-center">
                        <div className="col">
                        {data.pageUrl &&
                            <FacebookProvider appId="508296159963693">
                                <Page href={data.pageUrl} tabs="timeline" hideCover={true} hideCTA={true} smallHeader={true}/>
                            </FacebookProvider>
                        }
                        </div>
                    </div>
                </div>
            </div>
            {showOption &&
                <div ref={ref} className="pb-5" style={{
                    position: "absolute",
                    left: "101%",
                    backgroundColor: "black",
                    top: 0,
                    zIndex: "1000",
                    borderRadius: "5px",
                    width: "250px",
                    textAlign: "center",
                    padding: "10px"
                }}>
                    <span className="text-white">Page url</span>
                    <div className="row mt-3">
                        <div className="col text-left">
                            <input className="form-control" placeholder="Page url" type="text" value={data.pageUrl}
                                   onChange={(e) => {
                                       setValue('pageUrl', e.target.value)
                                   }}/>
                        </div>
                    </div>
                    <span className="text-white">Padding</span>
                    <div className="row mt-3">
                        <div className="col-12 text-left">
                            <span className="text-white">Top</span>
                        </div>
                        <div className="col-12 text-center">
                            <Slider
                                styles={{
                                    active: {
                                        backgroundColor: '#86d3ff'
                                    },
                                    thumb: {
                                        width: 20,
                                        height: 20,
                                        backgroundColor: '#2693e6'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                                xmax={50} xmin={0} xstep={1} axis="x"
                                x={(data.paddingTop || data.paddingTop === 0) ? data.paddingTop : 0}
                                onChange={({x}) => setValue('paddingTop', x)}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 text-left">
                            <span className="text-white">Bottom</span>
                        </div>
                        <div className="col-12 text-center">
                            <Slider
                                styles={{
                                    active: {
                                        backgroundColor: '#86d3ff'
                                    },
                                    thumb: {
                                        width: 20,
                                        height: 20,
                                        backgroundColor: '#2693e6'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                                xmax={50} xmin={0} xstep={1} axis="x"
                                x={(data.paddingBottom || data.paddingBottom === 0) ? data.paddingBottom : 0}
                                onChange={({x}) => setValue('paddingBottom', x)}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 text-left">
                            <span className="text-white">Left</span>
                        </div>
                        <div className="col-12 text-center">
                            <Slider
                                styles={{
                                    active: {
                                        backgroundColor: '#86d3ff'
                                    },
                                    thumb: {
                                        width: 20,
                                        height: 20,
                                        backgroundColor: '#2693e6'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                                xmax={50} xmin={0} xstep={1} axis="x"
                                x={(data.paddingLeft || data.paddingLeft === 0) ? data.paddingLeft : 0}
                                onChange={({x}) => setValue('paddingLeft', x)}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 text-left">
                            <span className="text-white">Right</span>
                        </div>
                        <div className="col-12 text-center">
                            <Slider
                                styles={{
                                    active: {
                                        backgroundColor: '#86d3ff'
                                    },
                                    thumb: {
                                        width: 20,
                                        height: 20,
                                        backgroundColor: '#2693e6'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                                xmax={50} xmin={0} xstep={1} axis="x"
                                x={(data.paddingRight || data.paddingRight === 0) ? data.paddingRight : 0}
                                onChange={({x}) => setValue('paddingRight', x)}/>
                        </div>
                    </div>
                    <span className="text-white">Background</span>
                    <div className="row mt-3">
                        <div className="col text-left">
                            <InputColor
                                initialValue={data.bgColor ? data.bgColor : "#000000"}
                                onChange={(color) => setValue('bgColor', color.hex)}
                                placement="right"
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Facebook
