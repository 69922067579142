import React,{useState} from "react";
import {login} from "../../redux/actions/userActions";
import {Link, Redirect} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import API from "../../utils/API";

const SetPassword = (props) => {
  const [password,setPassword] = useState(null)
  const [confirmpassword,setConfirmpassword] = useState(null)
  const [error,setError] = useState(null)

  const dispatch = useDispatch();

  const sign = useSelector(state => {
    return state.user.sign
  });

  const submit = () => {

    if (!password || password.length < 8) {
      setError('Password length should be minimum 8 characters')
      return;
    }

    if (password !== confirmpassword) {
      setError('Password and confirm password did not match')
      return;
    }

    API.post('/auth/set',{key:props.match.params.id,password:password})
        .then(data => {
          dispatch(login(data.data.data));
        })
        .catch(error => {
          console.log(error)
          setError('Invalid restore key')
        })
  }

  return (
      sign ?
          <Redirect to="/"/>
          :
          <React.Fragment>
            <div className="text-center mt-4">
              <h1 className="h2">Restore password</h1>
            </div>
            <Card>
              <CardBody>
                <div className="m-sm-4">
                  {error &&
                    <div className="text-center text-danger">{error}</div>
                  }
                  <Form>
                    <FormGroup>
                      <Label>Password</Label>
                      <Input
                          bsSize="lg"
                          type="password"
                          name="password"
                          placeholder="Enter password"
                          onChange={(e) => {setPassword(`${e.target.value}`);setError(null)}}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Confirm password</Label>
                      <Input
                          bsSize="lg"
                          type="password"
                          name="password"
                          placeholder="Confirm password"
                          onChange={(e) => {setConfirmpassword(`${e.target.value}`);setError(null)}}
                      />
                    </FormGroup>
                    <div className="text-center mt-3">
                        <Button color="primary" size="lg" onClick={submit}>
                          Save
                        </Button>
                    </div>
                  </Form>
                  <div className="text-center mt-2">
                    <small>
                      <Link to="/auth/sign-in">Back to Sign In</Link>
                    </small>
                  </div>
                </div>
              </CardBody>
            </Card>
          </React.Fragment>
  )
}

export default SetPassword;
