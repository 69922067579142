import React,{useState,useEffect} from "react"
import {
    Button,
    Card, CardBody,
    Container,
    Nav, NavItem, NavLink, TabContent, TabPane, ModalHeader, ModalBody, ModalFooter, Modal
} from "reactstrap";
import API from "../../utils/API";
import SubmissionStatusBadge from "../../components/status/SubmissionStatusBadge";
import SubmissionData from "./SubmissionData";
import classnames from "classnames";
import PreviewApp from "../../components/PreviewApp";
import SubmissionLogs from "./SubmissionLogs";
import Platforms from "./Platforms";
import Comments from "./Comments";
import StatusChange from "./StatusChange";

const Submission = (props) => {

    const [submission,setSubmission] = useState(null)
    const [reload,setReload] = useState(true)

    const [activeTab,setActiveTab] = useState("summary")

    const [requestInfoModal, setOpenRequestInfoModal] = useState(false)

    const [requestInfoMessage, setRequestInfoMessage] = useState('')

    useEffect(() => {
        loadSubmission();
    }, [props.match.params.id,reload])

    const loadSubmission = () => {
        API.get('/admin/appsubmit/item/'+props.match.params.id).then((response) => {
            setSubmission(response.data.data)
        });
    }

    const startWork = () => {
        API.put('/admin/appsubmit/start/'+props.match.params.id).then((response) => {
            setReload(!reload)
        });
    }

    const requestMoreInfo = () => {
        API.put('/admin/appsubmit/decline/' + props.match.params.id, {comment: requestInfoMessage}).then((response) => {
            loadSubmission();
            setOpenRequestInfoModal(false);
            setRequestInfoMessage('');
        });
    }

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">App submission</h1>
                    {submission &&
                        <>
                        <div className="row">
                            <div className="col-auto text-left">
                                <Card className="mt-2">
                                    <CardBody className="text-left">
                                        <ul className="list-unstyled mb-0">
                                            <li className="mt-1">App: <strong>{submission.app.title}</strong></li>
                                            <li className="mt-1"><PreviewApp id={submission.app.id}/></li>
                                            <li className="mt-1">Submission status: <StatusChange submission={submission.appsubmit}/></li>
                                            <li className="mt-1">Submission date: <strong>{submission.appsubmit.submitdate}</strong></li>
                                        </ul>
                                        <div className="row justify-content-center mt-3">
                                            {submission.appsubmit.status == 1 &&
                                                <div className="col-auto text-left">
                                                    <Button color={"success"} onClick={()=>startWork()}>Start work</Button>
                                                </div>
                                            }
                                            <div className="col-auto text-left">
                                                <Button color={"primary"} onClick={()=>setOpenRequestInfoModal(true)}>Request more info</Button>
                                            </div>
                                            <Modal
                                                isOpen={requestInfoModal}
                                                toggle={() => setOpenRequestInfoModal(!requestInfoModal)}
                                            >
                                                <ModalHeader toggle={() => setOpenRequestInfoModal(!requestInfoModal)}>
                                                    Request more info
                                                </ModalHeader>
                                                <ModalBody className="text-center m-3 p-4">
                                                    <label>Message to user</label>
                                                    <textarea className="form-control" onChange={(e)=>setRequestInfoMessage(e.target.value)}>{requestInfoMessage}</textarea>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button color="secondary" onClick={() => setOpenRequestInfoModal(!requestInfoModal)}>
                                                        Close
                                                    </Button>{" "}
                                                    <Button
                                                        color={'primary'}
                                                        onClick={() => requestMoreInfo()}
                                                    >
                                                        Save changes
                                                    </Button>
                                                </ModalFooter>
                                            </Modal>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col text-left">
                                <div className={"tab default"}>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "summary" })}
                                                onClick={() => {
                                                    setActiveTab("summary");
                                                }}
                                            >
                                                Summary
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "apps" })}
                                                onClick={() => {
                                                    setActiveTab("apps");
                                                }}
                                            >
                                                Platforms
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "logs" })}
                                                onClick={() => {
                                                    setActiveTab("logs");
                                                }}
                                            >
                                                Logs
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="summary">
                                            <SubmissionData submission={submission}/>
                                        </TabPane>
                                        <TabPane tabId="apps">
                                            <Platforms submissionId={submission.appsubmit.id}/>
                                        </TabPane>
                                        <TabPane tabId="logs">
                                            <SubmissionLogs submissionId={submission.appsubmit.id}/>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </div>
                        </div>
                        {submission &&
                            <div className="mt-2"><Comments submissionid={submission.appsubmit.id}/></div>
                        }
                        </>
                    }
        </Container>
    )
}

export default Submission
