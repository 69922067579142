import React,{useState,useEffect} from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row, Table
} from "reactstrap";
import API from "../../utils/API";
import Loader from "../../components/Loader";
import DateRange from "../../components/formfields/DateRange";
import dateFormat from "dateformat";

const PaymentsSummary = () => {
    const defaultDate = dateFormat((new Date()).setDate((new Date()).getDate() - 30),"mmm d, yyyy")+" to "+dateFormat(new Date(),"mmm d, yyyy")

    const [filtration,setFiltration] = useState({page:1,perPage:100,date:defaultDate})
    const [payments, setPayments] = useState(null)
    const [loading, setLoading] = useState(true)

    const changeData = (name,value) => {
        setFiltration({ ...filtration, [name]: value })
    }

    useEffect(() => {
        setLoading(true)
        API.post('/admin/billing/paymentssummary',filtration).then((response) => {
            setPayments(response?.data?.data)
            setLoading(false)
        });
    }, [filtration])

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Transactions summary</h1>
            <div className="row mt-1 mb-2">
                <div className="col-3">
                    <label>Transaction date</label>
                    <DateRange
                        onChange={changeData}
                        name={'date'}
                        value={defaultDate}
                    />
                </div>
            </div>
            <Row>
                <Col>
                    <Card>
                        {loading &&
                            <CardBody><Loader/></CardBody>
                        }
                        {(!loading && payments && payments.length > 0) ?
                            <div className="table-responsive">
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Amount</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        payments.map((payment, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{payment.date}</td>
                                                    <td>${payment.amount}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </div>
                            :
                            !loading &&
                            <CardBody className="text-center"><h5>Data not found</h5></CardBody>
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default PaymentsSummary;
