import React,{useState,useEffect} from "react"
import API from "../../utils/API";

const Comments = ({submissionid}) => {
    const [message, setMessage] = useState(null);
    const [comments,setComments] = useState(null);

    const loadMessages = () => {
        API.get('/admin/appsubmit/comments/'+submissionid).then((response) => {
            setComments(response.data.data);
        });
    }

    const addComment = () => {
        API.put('/admin/appsubmit/submit/comment/'+submissionid,{
            comment:message
        }).then((response) => {
            setMessage('');
            loadMessages();
        });
    }

    useEffect(() => {
        loadMessages();

        const interval = setInterval(() => {
            loadMessages();
        }, 600);

        return () => clearInterval(interval);
    }, [submissionid])

    return <div>
        <div className="row">
            <div className="col">
                <input type="text" className="form-control" style={{borderRadius:"10px"}} value={message} onChange={(e) => setMessage(e.target.value)}/>
            </div>
            <div className="col-auto">
                <button className="btn btn-primary" style={{borderRadius:"10px"}} onClick={()=>addComment()}>Add</button>
            </div>
        </div>
        {comments &&
            comments.map((comment) => {
                return <div key={comment.id} className={`row mt-2 ${!comment.fromadmin?'justify-content-end':''}`}>
                    <div className={`col-4 ${!comment.fromadmin?'bg-secondary':'bg-primary'} text-white p-3`} style={{borderRadius:"10px"}}>
                    <div>
                        {comment.fromadmin ?
                            <strong>You</strong>
                            :
                            <strong>User</strong>
                        }
                    </div>
                    <div>{comment.date}</div>
                        {comment.content}
                    </div>
                </div>
            })
        }
    </div>
}

export default Comments;
