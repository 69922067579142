import React, {useState} from "react";
import {Link, Redirect} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import API from "../../utils/API";
import {login} from "../../redux/actions/userActions";

const SignIn = () => {
  const [email,setEmail] = useState(null)
  const [password,setPassword] = useState(null)
  const [error,setError] = useState(null)
  const [loading,setLoading] = useState(false)

  const dispatch = useDispatch();

  const sign = useSelector(state => {
    return state.user.sign
  });

  const submit = () => {
    if (!loading) {
      setLoading(true)
      API.post('/auth/signin', {email: email, password: password})
          .then(data => {
            dispatch(login(data.data.data));
            setLoading(false)
          })
          .catch(error => {
            setError('Invalid username/password')
            setLoading(false)
          })
    }
  }

  return (
      sign ?
          <Redirect to="/"/>
          :
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="m-sm-4">
              <div className="text-center">
                App Builder Admin
              </div>
              {error &&
                <div className="text-center text-danger">{error}</div>
              }
              <Form>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                      bsSize="lg"
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      onChange={(e) => {setEmail(`${e.target.value}`);setError(null)}}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Password</Label>
                  <Input
                      bsSize="lg"
                      type="password"
                      name="password"
                      placeholder="Enter your password"
                      onChange={(e) => {setPassword(`${e.target.value}`);setError(null)}}
                  />
                  <small>
                    <Link to="/auth/reset-password">Forgot password?</Link>
                  </small>
                </FormGroup>
                <div className="text-center mt-3">
                  <Button color="primary" size="lg" onClick={submit}>
                    {loading ?
                        <>Loading...</>
                        :
                        <>Sign in</>
                    }
                  </Button>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
  )
}

export default SignIn;
