import React,{useEffect,useState,useRef} from "react"
import InlineEditableText from "../InlineEditableText";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import Switch from "react-switch";
import InputColor from "react-input-color";
import Slider from "react-input-slider";
import { v4 as uuidv4 } from 'uuid';
import PhotogalleryItem from "./PhotogalleryItem";
import 'react-slideshow-image/dist/styles.css'
import {Input} from "reactstrap";
import Gallery from "react-photo-gallery";
import GridGallery from 'react-grid-gallery';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Photogallery = (props) => {
    const [data,setData] = useState((props.data)?props.data:props.defaultdata?props.defaultdata:{titleActive:true,style:'list'})

    const [showOption, setShowOptions] = useState(false)

    const ref = useRef();

    const handleClickOutside = e => {
        if (ref && ref.current && !ref.current.contains(e.target)) {
            setShowOptions(false)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    },[]);

    const setImageData = (image) => {
        let images = data?.images??[]
        let n = images?.length??0
        let i, set = false
        for (i=0; i < n; i++) {
            if (images[i]?.id === image.id) {
                images[i] = image
                set = true
            }
        }

        if (!set) {
            images.push(image)
        }

        setValue('images',images)
    }

    const deleteImage = (id) => {
        let images = data?.images??[]
        let newImages = [], i, n = images?.length??0
        for (i=0; i < n; i++) {
            if (images[i]?.id !== id) {
                newImages.push(images[i])
            }
        }

        setValue('images',newImages)
    }

    const setValue = (name,value) => {
        let newData = {
            ...data,
            [name]: value
        }

        setData(newData)
        props.setValue(newData)
    }

    let mainStyle = {
        backgroundColor: data?.bgColor??'#FFFFFF',
        border:(props.isResult)?"dashed 1px #64afb9":"0",
        paddingTop: (data?.paddingTop || data?.paddingTop === 0)?data.paddingTop:0,
        paddingBottom: (data?.paddingBottom || data?.paddingBottom === 0)?data.paddingBottom:0,
    }

    const newImage = () => {
        setImageData({id:uuidv4(),src:'https://gettow.s3.amazonaws.com/image/61e670c73d5e4.jpeg',width:1000,height:667})
    }

    let formatForGrid = (images) => {
        let newImages = [], n = images?.length??0, i, tempImage

        for (i=0; i < n; i++) {
            tempImage = images[i]

            tempImage.thumbnail = tempImage.src
            tempImage.thumbnailWidth = tempImage.width
            tempImage.thumbnailHeight = tempImage.height
            tempImage.caption = tempImage.title
            newImages.push(tempImage)
        }

        return newImages
    }

    let formatForCarousel = (images) => {
        let newImages = [], n = images?.length??0, i, tempImage

        for (i=0; i < n; i++) {
            tempImage = images[i]

            tempImage.original = tempImage.src
            tempImage.thumbnail = tempImage.src
            tempImage.originalWidth = tempImage.width
            tempImage.originalHeight = tempImage.height
            tempImage.thumbnailWidth = tempImage.width
            tempImage.thumbnailHeight = tempImage.height
            tempImage.originalTitle = tempImage.title
            newImages.push(tempImage)
        }

        return newImages
    }

    return (
        <div style={{position:"relative"}} onClick={(e)=>{if (e.target.tagName.toLowerCase() === 'div'){
            if (props.isResult) {
                setShowOptions(true)
            }
        }}}>
            <div style={mainStyle} className="d-flex">
                <div className="w-100 text-white bTitleText">
                    {data?.titleActive &&
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <InlineEditableText isResult={props.isResult} name="title"
                                                    setText={setValue}>{data?.title??'<h1 class="ql-align-center"><span style="color: rgb(0, 0, 255);">Photo gallery</span></h1>'}</InlineEditableText>
                            </div>
                        </div>
                    }
                    {data?.subtitleActive &&
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <InlineEditableText isResult={props.isResult} name="subtitle"
                                                    setText={setValue}>{data?.subtitle??'<p class="ql-align-center"><span style="color: rgb(0, 0, 0);">Sub title</span></p>'}</InlineEditableText>
                            </div>
                        </div>
                    }
                    {data?.images?.length > 0 &&
                        <div className="row justify-content-center">
                            <div className="col">
                                {data?.style === 'list' &&
                                    <Gallery photos={data.images} columns={2}/>
                                }
                                {data?.style === 'grid' &&
                                    <GridGallery images={formatForGrid(data.images)} enableImageSelection={false}/>
                                }
                                {data?.style === 'carousel' &&
                                    <Carousel>
                                        {data.images.map((image,index)=>{
                                            return (
                                                <div>
                                                    <img src={image.src} />
                                                    {image?.title?.length &&
                                                        <p className="legend">{image.title}</p>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </Carousel>
                                }
                            </div>
                        </div>
                    }
                    {data?.textActive &&
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <InlineEditableText
                                    isResult={props.isResult}
                                    name="text"
                                    setText={setValue}
                                >
                                    {data.text??'<p class="ql-align-center"><span style="color: rgb(0, 0, 0);">Text</span></p>'}
                                </InlineEditableText>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {showOption &&
                <div ref={ref} className="pb-5" style={{position:"absolute",left:"101%",backgroundColor:"black",top:0,zIndex:"1000",borderRadius:"5px",width:"250px",textAlign:"center",padding:"10px"}}>
                    <span className="text-white">Style</span>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">List</span>
                        </div>
                        <div className="col-6 text-right">
                            <Input type="radio" style={{width:25,height:25}} name="style" checked={data.style==='list'} onChange={() => setValue('style','list')}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Grid</span>
                        </div>
                        <div className="col-6 text-right">
                            <Input type="radio" style={{width:25,height:25}} name="style" checked={data.style==='grid'} onChange={() => setValue('style','grid')}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Carousel</span>
                        </div>
                        <div className="col-6 text-right">
                            <Input type="radio" style={{width:25,height:25}} name="style" checked={data.style==='carousel'} onChange={() => setValue('style','carousel')}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Title</span>
                        </div>
                        <div className="col-6 text-right">
                            <Switch
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                checked={data?.titleActive}
                                onChange={(nextChecked)=>setValue('titleActive',nextChecked)}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Subtitle</span>
                        </div>
                        <div className="col-6 text-right">
                            <Switch onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={30}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                    checked={data?.subtitleActive} onChange={(nextChecked)=>setValue('subtitleActive',nextChecked)}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Text</span>
                        </div>
                        <div className="col-6 text-right">
                            <Switch onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={30}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                    checked={data?.textActive} onChange={(nextChecked)=>setValue('textActive',nextChecked)}/>
                        </div>
                    </div>
                    <span className="text-white">Padding</span>
                    <div className="row mt-1">
                        <div className="col-12 text-left">
                            <span className="text-white">Top</span>
                        </div>
                        <div className="col-12 text-center">
                            <Slider
                                styles={{
                                    active: {
                                        backgroundColor: '#86d3ff'
                                    },
                                    thumb: {
                                        width: 20,
                                        height: 20,
                                        backgroundColor: '#2693e6'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                                xmax={50} xmin={0} xstep={1} axis="x" x={(data?.paddingTop || data?.paddingTop === 0)?data.paddingTop:0} onChange={({ x }) => setValue('paddingTop',x)} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 text-left">
                            <span className="text-white">Bottom</span>
                        </div>
                        <div className="col-12 text-center">
                            <Slider
                                styles={{
                                    active: {
                                        backgroundColor: '#86d3ff'
                                    },
                                    thumb: {
                                        width: 20,
                                        height: 20,
                                        backgroundColor: '#2693e6'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                                xmax={50} xmin={0} xstep={1} axis="x" x={(data?.paddingBottom || data?.paddingBottom === 0)?data.paddingBottom:0} onChange={({ x }) => setValue('paddingBottom',x)} />
                        </div>
                    </div>
                    <div className="text-white text-left">Background</div>
                    <div className="row mt-3">
                        <div className="col text-left">
                            <InputColor
                                initialValue={data?.bgColor??"#ffffff"}
                                onChange={(color)=>setValue('bgColor',color.hex)}
                                placement="right"
                            />
                        </div>
                    </div>
                    <span className="text-white">Images</span>
                    <div className="text-center mt-2">
                        <button className="btn btn-outline-primary" onClick={()=>newImage()}>Add image</button>
                    </div>
                    {data?.images?.map((image,id)=>{
                        return <div key={"imageItem"+id}><PhotogalleryItem data={image} setImageData={setImageData} deleteImage={deleteImage}/></div>
                    })}
                </div>
            }
        </div>
    )
}

export default Photogallery
