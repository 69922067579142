import React,{useState,useEffect} from "react";
import API from "../../utils/API";
import Select from "react-select";

const ProductSelect = ({name,value,onChange}) => {
    const [data,setData] = useState(null)

    const changeSelect = (value) => {
        let i, values = []
        for (i = 0; i < value?.length; i++) {
            values.push(value[i].value)
        }
        if (name) {
            onChange(name, values)
        }
    }

    useEffect(() => {
        API.get('/admin/system/products').then((response) => {
            let options,i,dataList = []
            if (response.data.data) {
                options = response.data.data
                for (i=0;i<options.length;i++) {
                    dataList.push({value:options[i].id,label:options[i].title})
                }
            }
            setData(dataList)
        });
    }, [])

    return (
        <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={data}
            value={value}
            onChange={(value)=>changeSelect(value)}
            isMulti={true}
            placeholder="Select..."
        />
    )
}

export default ProductSelect;
