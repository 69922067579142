import React,{useState,useEffect} from "react"
import {Badge} from "reactstrap";
import {Edit2} from "react-feather";
import API from "../../utils/API";

const PublishStatus = ({publish}) => {
    const [edit,setEdit] = useState(false)
    const [status,setStatus] = useState(publish.status)

    const saveStatus = () => {
        if (status !== publish.status) {
            API.put('/admin/appsubmit/publish/status/'+publish.id,{status:status}).then((response) => {
                window.location.reload();
            });
        }
    }

    return <>
        {edit ?
        <div className="row">
            <div className="col-8">
            <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value="inwork">In work</option>
                <option value="submitted">Submitted</option>
                <option value="published">Published</option>
                <option value="rejected">Rejected</option>
            </select>
            </div>
            <div className="col-4">
                <button className="btn btn-success" disabled={status === publish.status} onClick={()=>saveStatus()}>Save</button>
            </div>
        </div>
        :
        <div className="cursor-pointer">
            <Badge color="primary" className="mr-1 mb-1">{publish.status}</Badge> <Edit2 onClick={()=>setEdit(true)} size={16}/>
        </div>
        }
    </>
}

export default PublishStatus
