import React from "react"

const Templates = (props) => {

    return (
        <iframe
            style={{width: "100%", height: "100%", border: "0"}}
            src={`${process.env.REACT_APP_URL}/app/${props.match.params.id}/pages?managementToken=${localStorage.getItem('jwt')}`}
        />
    )
}

export default Templates
