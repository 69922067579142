import React from "react";
import Header1 from "./blocks/header/Header1";
import Video from "./blocks/videoimage/Video";
import Sociallinks from "./blocks/social/Sociallinks";
import Intro from "./blocks/header/Intro";
import Slideshow from "./blocks/videoimage/Slideshow";
import Social from "./blocks/social/Social";
import Pagesearch from "./blocks/search/Pagesearch";
import Button from "./blocks/feature/Button";
import Form from "./blocks/feature/Form";
import Table from "./blocks/feature/Table";
import Datetime from "./blocks/feature/Datetime";
import Termsandconditions from "./blocks/feature/Termsandconditions";
import Blogfeed from "./blocks/feature/Blogfeed";
import Image from "./blocks/feature/Image";
import Text from "./blocks/feature/Text";
import Ageverification from "./blocks/feature/Ageverification";
import FAQ from "./blocks/feature/FAQ";
import Photogallery from "./blocks/videoimage/Photogallery";
import Menu from "./blocks/feature/RestaurantMenu/Menu";
import LogoShowcase from "./blocks/feature/LogoShowcase/LogoShowcase";
import Facebook from "./blocks/social/feeds/Facebook";
import Instagram from "./blocks/social/feeds/Instagram";
import Pinterest from "./blocks/social/feeds/Pinterest";
import Twitter from "./blocks/social/feeds/Twitter";

function blockFactory(slug)
{
    switch (slug.toLowerCase()) {
        case 'header1':
            return <Header1/>
        case 'video':
            return <Video/>
        case 'sociallinks':
            return <Sociallinks/>
        case 'intro':
            return <Intro/>
        case 'slideshow':
            return <Slideshow/>
        case 'social':
            return <Social/>
        case 'pagesearch':
            return <Pagesearch/>
        case 'button':
            return <Button/>
        case 'form':
            return <Form/>
        case 'table':
            return <Table/>
        case 'datetime':
            return <Datetime/>
        case 'termsandconditions':
            return <Termsandconditions/>
        case 'blogfeed':
            return <Blogfeed/>
        case 'image':
            return <Image/>
        case 'text':
            return <Text/>
        case 'ageverification':
            return <Ageverification/>
        case 'faq':
            return <FAQ/>
        case 'photogallery':
            return <Photogallery/>
        case 'restaurantmenu':
            return <Menu/>
        case 'logoshowcase':
            return <LogoShowcase/>
        case 'facebookfeed':
            return <Facebook/>
        case 'instagramfeed':
            return <Instagram/>
        case 'pinterestfeed':
            return <Pinterest/>
        case 'twitterfeed':
            return <Twitter/>
        default:
            return <></>
    }
}

export default blockFactory
