import React,{useState,useEffect} from "react"
import API from "../../utils/API";
import {Edit2} from "react-feather";

const PlatformUrl = ({publishId}) => {
    const [publish,setPublish] = useState(null);
    const [edit,setEdit] = useState(false);
    const [url,setUrl] = useState(null);

    const loadPublish = () => {
        API.get('/admin/appsubmit/publish/'+publishId).then((response) => {
            setPublish(response.data.data);
            setUrl(response.data.data.url);
        });
    }

    const saveUrl = () => {
        API.put('/admin/appsubmit/publish/url/'+publishId,{url:url}).then((response) => {
            setEdit(false);
            loadPublish();
        });
    }

    useEffect(() => {
        loadPublish();
    }, [publishId])

    return (
        <div>
            {publish &&
                <>
                    {publish.url}
                    {edit ?
                        <>
                            <div className="row">
                                <div className="col">
                                    <input type="text" className="form-control" onChange={(e)=>setUrl(e.target.value)} value={url}/>
                                </div>
                                <div className="col-auto">
                                    <button className="btn btn-primary" onClick={()=>saveUrl()}>Save</button>
                                </div>
                            </div>
                        </>
                        :
                        <Edit2 onClick={()=>setEdit(true)} size={16}/>
                    }
                </>
            }
        </div>
    )
}

export default PlatformUrl
