import React,{useState,useEffect} from "react";
import {useHistory} from "react-router-dom";
import API from "../../utils/API";
import {toastr} from "react-redux-toastr";
import {Button, Card, CardBody, Container, FormGroup, Label} from "reactstrap";
import {AvField, AvForm, AvInput} from "availity-reactstrap-validation";
import Blockcategory from "../../components/formfields/Blockcategory";
import ImageUploader from "react-images-upload";

const BlockSettings = (props) => {
    const [block, setBlock] = useState({title:null,priority:1,slug:null,defaultdata:null});

    let history = useHistory();

    useEffect(()=>{
        if (props.match.params.id) {
            API.get('/admin/block/item/'+props.match.params.id)
                .then((response) => {
                    setBlock(response.data)
                })
        }
    },props.match.params.id)

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Module {block.title?<>"{block.title}"</>:""} default content</h1>
            <Card>
                <CardBody>
                    <div className="row">
                        <div className="col-12">
                            <iframe style={{width:"800px",height:"600px"}} src={`${process.env.REACT_APP_URL}/admin/defaultdata/${block.slug}?blockToken=${localStorage.getItem('jwt')}`}/>
                        </div>
                    </div>
                    <div>
                        <Button onClick={()=>history.push("/blocks/list")} color="primary">Back</Button>
                    </div>
                </CardBody>
            </Card>
        </Container>
    )
}

export default BlockSettings
