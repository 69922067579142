import React,{useState,useEffect} from "react";
import {useHistory} from "react-router-dom";
import API from "../../utils/API";
import {
    Button,
    Card,
    Col,
    Container,
    Row, Table
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Edit, Settings} from "react-feather";
import blockFactory from "../builder/blockFactory";

const Blocks = (props) => {
    const [blocks, setBlocks] = useState(null)
    let history = useHistory();

    useEffect(() => {
        API.get('/admin/block/all').then((response) => {
            setBlocks(response.data.data)
        });
    }, [])

    const newBlock = () => {
        history.push("/blocks/item")
    }

    const editBlock = (id) => {
        history.push("/blocks/item/"+id);
    }

    const setDataValue = (id) => {
        history.push("/blocks/settings/"+id);
    }

    return (
        <Container fluid className="p-0">
            <Button color="primary" onClick={newBlock} className="float-right mt-n1">
                <FontAwesomeIcon icon={faPlus}/> New module
            </Button>
            <h1 className="h3 mb-3">Modules</h1>
            <div className="row">
                {(blocks && blocks.length > 0) &&
                    blocks.map((block, index) => {
                        return (
                            <div className="col-3 m-3" key={index}>
                                <Card className="text-center">
                                    <div className="appPreview">
                                    {(block.slug && blockFactory(block.slug) !== false) &&
                                        block.image &&
                                            <img src={block.image} className="img-fluid"/>
                                    }
                                    </div>
                                    <h4 className="m-3">{block.title}</h4>
                                    <div className="row justify-content-between m-3">
                                        <div className="col-auto">
                                            <strong>{block.categoryData.title}</strong>
                                        </div>
                                        <div className="col-auto">
                                            <Settings onClick={() => {setDataValue(block.id)}} className="align-middle mr-1 cursor-pointer text-primary" size={18} />
                                        </div>
                                        <div className="col-auto">
                                            <Edit onClick={() => {editBlock(block.id)}} className="align-middle mr-1 cursor-pointer text-primary" size={18} />
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        )
                    })
                }
            </div>
        </Container>
    )
}

export default Blocks
