import React,{useState,useEffect} from "react";
import API from "../../utils/API"

const BillingCustomer = ({id}) => {
    const [data,setData] = useState(null)

    useEffect(() => {
        API.get('/admin/users/billingcustomer/'+id).then((response) => {
            setData(response.data.data)
        });
    }, [id])

    return (
        <>
            {data &&
                <>
                    <strong>Billing customer</strong>
                    <div>
                        Stripe ID: {data.externalid}
                    </div>
                </>
            }
        </>
    )
}

export default BillingCustomer;
