import React,{useState,useEffect} from "react";
import {useHistory} from "react-router-dom";
import API from "../../utils/API";
import {toastr} from "react-redux-toastr";
import {Button, Card, CardBody, Container, FormGroup, Label} from "reactstrap";
import {AvField, AvForm, AvInput} from "availity-reactstrap-validation";

const Faq = (props) => {
    const [category, setCategory] = useState({status:1,category:"payment"});
    const [error, setError] = useState(null)

    let history = useHistory();

    const handleChange = (e) => {
        setCategory({ ...category, [e.target.name]: e.target.value })
        setError(null)
    };

    const submit = () => {
        if (category.title) {
            if (props.match.params.id) {
                API.post('/admin/faq/save/' + props.match.params.id, category)
                    .then((response) => {
                        toastr.success('Success', 'FAQ was successfully saved')
                        history.push("/settings/faqs")
                    })
                    .catch(error => {
                        setError('Invalid category data')
                    })
            } else {
                API.post('/admin/faq/add', category)
                    .then((response) => {
                        toastr.success('Success', 'FAQ was successfully saved')
                        history.push("/settings/faqs")
                    })
                    .catch(error => {
                        setError('Invalid faq data')
                    })

            }
        }
    }

    useEffect(()=>{
        if (props.match.params.id) {
            API.get('/admin/faq/item/'+props.match.params.id)
                .then((response) => {
                    setCategory(response.data.data)
                })
        }
    },props.match.params.id)

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">{props.match.params.id?'Edit':'New'} faq</h1>
            <Card>
                <CardBody>
                    {error &&
                        <div className="text-center text-danger">{error}</div>
                    }
                    <AvForm onValidSubmit={submit}>
                        <FormGroup>
                            <Label>Status</Label>
                            <AvField
                                type="select"
                                name="status"
                                value={category.status}
                                onChange={handleChange}
                            >
                                <option value={1}>Active</option>
                                <option value={2}>Not Active</option>
                            </AvField>
                        </FormGroup>
                        <FormGroup>
                            <Label>Category</Label>
                            <AvField
                                type="select"
                                name="category"
                                value={category.category}
                                onChange={handleChange}
                                >
                                <option value="payment">Payment</option>
                                <option value="app">App features</option>
                                <option value="dashboard">Dashboard</option>
                                <option value="statistic">Statistic</option>
                            </AvField>
                        </FormGroup>
                        <FormGroup>
                            <Label>Question</Label>
                            <AvInput onChange={handleChange} required type="text" name="title" value={category.title} placeholder="Question" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Answer</Label>
                            <AvInput onChange={handleChange} rows={4} required type="textarea" name="content" value={category.content} placeholder="Answer" />
                        </FormGroup>

                        <div className="text-center">
                            <Button color="primary">Save</Button>
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        </Container>
    )
}

export default Faq
