import React,{useEffect,useState} from "react"
import API from "../../utils/API"
import {Card, CardBody, Col, Container, Row, Table, Badge} from "reactstrap";
import Loader from "../../components/Loader";
import {useHistory} from "react-router-dom";
import PreviewApp from "../../components/PreviewApp";

const Apps = () => {
    const [apps,setApps] = useState(null)
    let history = useHistory()
    useEffect(()=>{
        API.post('/admin/apps',{}).then((response) => {
            setApps(response.data.data)
        });
    },[])

    const editUser = (userId) => {
        history.push("/user/item/"+userId);
    }

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Applications</h1>
            <Row>
                <Col>
                    <Card>
                        {apps ?
                            <div className="table-responsive">
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>User</th>
                                        <th>Date add</th>
                                        <th>Subscription</th>
                                        <th>Setup payment</th>
                                        <th>View app</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        apps.map((app, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{app.app.title}</td>
                                                    <td><a onClick={() => {editUser(app.user.id)}} className="text-primary cursor-pointer">{app.user.name}</a></td>
                                                    <td>{app.app.dateadd}</td>
                                                    <td>{app.billingproduct?.title?<Badge color="success" className="mr-1 mb-1">{app.billingproduct?.title}</Badge>:''}</td>
                                                    <td>{app.billingsubmissionpayment?.paymentdate?<Badge color="primary" className="mr-1 mb-1">{app.billingsubmissionpayment?.paymentdate}</Badge>:''}</td>
                                                    <td><PreviewApp id={app.app.id}/></td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </div>
                            :
                            <CardBody><Loader/></CardBody>
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Apps
