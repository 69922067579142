import React,{useState,useEffect} from "react";
import {useHistory} from "react-router-dom";
import API from "../../utils/API";
import {Button, Card, Col, Container, Row, Table} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Edit2} from "react-feather";

const TemplateCategories = (props) => {
    const [blocks, setBlocks] = useState(null)
    let history = useHistory();

    useEffect(() => {
        API.get('/admin/apptemplate/categories').then((response) => {
            setBlocks(response.data.data)
        });
    }, [])

    const newCategory = () => {
        history.push("/templates/category")
    }

    const editCategory = (id) => {
        history.push("/templates/category/"+id);
    }

    return (
        <Container fluid className="p-0">
            <Button color="primary" onClick={newCategory} className="float-right mt-n1">
                <FontAwesomeIcon icon={faPlus}/> New category
            </Button>
            <h1 className="h3 mb-3">Template categories</h1>
            <Row>
                <Col>
                    <Card>
                        {(blocks && blocks.length > 0) &&
                            <div className="table-responsive">
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Edit</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        blocks.map((block, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{block.title}</td>
                                                    <td className="table-action">
                                                        <Edit2 onClick={() => {editCategory(block.id)}} className="align-middle mr-1 cursor-pointer text-primary" size={18} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default TemplateCategories
