import React,{useState,useEffect} from "react";
import {useHistory} from "react-router-dom";
import API from "../../utils/API";
import {toastr} from "react-redux-toastr";
import {Button, Card, CardBody, Container, FormGroup, Label} from "reactstrap";
import {AvField, AvForm, AvInput} from "availity-reactstrap-validation";

const SubscriptionPlan = (props) => {
    const [category, setCategory] = useState(null);
    const [error, setError] = useState(null)

    let history = useHistory();

    const handleChange = (e) => {
        setCategory({ ...category, [e.target.name]: e.target.value })
        setError(null)
    };

    const submit = () => {
        if (props.match.params.id) {
            API.post('/admin/billing/plan/' + props.match.params.id, category)
                .then((response) => {
                    toastr.success('Success', 'Plan was successfully saved')
                    history.push("/settings/subscriptioplans")
                })
                .catch(error => {
                    setError('Invalid data')
                })
        }
    }

    useEffect(()=>{
        if (props.match.params.id) {
            API.get('/admin/billing/plan/'+props.match.params.id)
                .then((response) => {
                    setCategory(response.data.data)
                })
        }
    },props.match.params.id)

    return (
        <Container fluid className="p-0">
            {category &&
                <>
                    <h1 className="h3 mb-3">{props.match.params.id?'Edit':'New'} subscription plan</h1>
                    <Card>
                        <CardBody>
                            {error &&
                                <div className="text-center text-danger">{error}</div>
                            }
                            <AvForm onValidSubmit={submit}>
                                <FormGroup>
                                    <Label>Title</Label>
                                    <AvInput readonly type="text" name="title" value={category.title} placeholder="Title" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Description</Label>
                                    <AvInput onChange={handleChange} rows={4} required type="textarea" name="description" value={category.description} placeholder="Description" />
                                </FormGroup>

                                <div className="text-center">
                                    <Button color="primary">Save</Button>
                                </div>
                            </AvForm>
                        </CardBody>
                    </Card>
                </>
            }
        </Container>
    )
}

export default SubscriptionPlan
