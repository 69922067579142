import React,{useState,useEffect} from "react"
import {Edit2} from "react-feather";
import API from "../../utils/API";
import SubmissionStatusBadge from "../../components/status/SubmissionStatusBadge";

const StatusChange = ({submission}) => {
    const [edit,setEdit] = useState(false)
    const [status,setStatus] = useState(submission.status)
    const [canComplete,setCanComplete] = useState(false)

    useEffect(() => {
        API.get('/admin/appsubmit/canbereleased/'+submission.id).then((response) => {
            setCanComplete(response.data.data.result);
        });
    }, [submission]);

    const saveStatus = () => {
        if (status !== submission.status) {
            API.put('/admin/appsubmit/submit/status/'+submission.id,{status:status}).then((response) => {
                window.location.reload();
            });
        }
    }

    return <>
        {edit ?
            <div className="row">
                <div className="col-8">
                    <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                        <option value="2">In work</option>
                        <option value="4">Need more info</option>
                        <option value="3" disabled={!canComplete}>Completed</option>
                    </select>
                </div>
                <div className="col-4">
                    <button className="btn btn-success" disabled={status === submission.status} onClick={()=>saveStatus()}>Save</button>
                </div>
            </div>
            :
            <div className="cursor-pointer">
                <SubmissionStatusBadge status={submission.status}/> <Edit2 onClick={()=>setEdit(true)} size={16}/>
            </div>
        }
    </>
}

export default StatusChange
