import React,{useEffect,useState,useRef} from "react"
import InlineEditableText from "../InlineEditableText"
import Switch from "react-switch"
import InputColor from 'react-input-color'
import Slider from 'react-input-slider'

const Pagesearch = (props) => {

    const [showOption, setShowOptions] = useState(false)
    const [data, setData] = useState((props.data)?props.data:props.defaultdata?props.defaultdata:{bgColor:"#ffffff",fieldBgColor:"#ffffff",textColor:"#000000",fieldRadius:0,searchTitle:true,searchPageName:true,searchPageDescription:true,searchSubtitle:true,searchText:true,searchImageName:true})

    const ref = useRef();

    const handleClickOutside = e => {
        if (ref && ref.current && !ref.current.contains(e.target)) {
            setShowOptions(false)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    },[]);

    const setValue = (name,value) => {
        let newData = {
            ...data,
            [name]: value
        }

        setData(newData)
        props.setValue(newData)
    }

    let image = {
        backgroundColor: data.bgColor?data.bgColor:'#ffffff',
        position:"relative",
        border:(props.isResult)?"dashed 1px #64afb9":"0",
        paddingTop: (data.paddingTop || data.paddingTop === 0)?data.paddingTop:0,
        paddingBottom: (data.paddingBottom || data.paddingBottom === 0)?data.paddingBottom:0,
    }

    return (
        <div style={{position:"relative"}} onClick={(e)=>{if (e.target.tagName.toLowerCase() === 'div'){
            if (props.isResult) {
                setShowOptions(true)
            }
        }}}>
            <div style={image} className="d-flex">
                <div className="w-100 text-white bTitleText pt-1 pb-1">
                    {data?.titleActive &&
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <InlineEditableText isResult={props.isResult} name="title"
                                                setText={setValue}>{data?.title ? data?.title : '<h1 class="ql-align-center"><span style="color: rgb(0, 0, 0);">Search</span></h1>'}</InlineEditableText>
                        </div>
                    </div>
                    }
                    {data?.subtitleActive &&
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <InlineEditableText isResult={props.isResult} name="subtitle"
                                                setText={setValue}>{data?.subtitle ? data.subtitle : '<p class="ql-align-center"><span style="color: rgb(0, 0, 0);">Sub title</span></p>'}</InlineEditableText>
                        </div>
                    </div>
                    }
                    <div className="row justify-content-center mt-2 pr-2 pl-2">
                        <input placeholder={data?.placeholder} className="form-control w-75" style={{backgroundColor:data.fieldBgColor, borderRadius:data.fieldRadius, color:data.textColor}}/>
                    </div>
                </div>
            </div>
            {showOption &&
            <div ref={ref} className="pb-5" style={{position:"absolute",left:"101%",backgroundColor:"black",top:0,zIndex:"1000",borderRadius:"5px",width:"250px",textAlign:"center",padding:"10px"}}>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Title</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            checked={data.titleActive}
                            onChange={(nextChecked)=>setValue('titleActive',nextChecked)}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Subtitle</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                checked={data.subtitleActive} onChange={(nextChecked)=>setValue('subtitleActive',nextChecked)}/>
                    </div>
                </div>
                <span className="text-white">Padding</span>
                <div className="row mt-3">
                    <div className="col-12 text-left">
                        <span className="text-white">Top</span>
                    </div>
                    <div className="col-12 text-center">
                        <Slider
                            styles={{
                                active: {
                                    backgroundColor: '#86d3ff'
                                },
                                thumb: {
                                    width: 20,
                                    height: 20,
                                    backgroundColor: '#2693e6'
                                },
                                disabled: {
                                    opacity: 0.5
                                }
                            }}
                            xmax={50} xmin={0} xstep={1} axis="x" x={(data.paddingTop || data.paddingTop === 0)?data.paddingTop:0} onChange={({ x }) => setValue('paddingTop',x)} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 text-left">
                        <span className="text-white">Bottom</span>
                    </div>
                    <div className="col-12 text-center">
                        <Slider
                            styles={{
                                active: {
                                    backgroundColor: '#86d3ff'
                                },
                                thumb: {
                                    width: 20,
                                    height: 20,
                                    backgroundColor: '#2693e6'
                                },
                                disabled: {
                                    opacity: 0.5
                                }
                            }}
                            xmax={50} xmin={0} xstep={1} axis="x" x={(data.paddingBottom || data.paddingBottom === 0)?data.paddingBottom:0} onChange={({ x }) => setValue('paddingBottom',x)} />
                    </div>
                </div>
                <div className="text-white text-left mt-3">Background</div>
                <div className="row">
                    <div className="col text-left">
                        <InputColor
                            initialValue={data.bgColor?data.bgColor:"#ffffff"}
                            onChange={(color)=>setValue('bgColor',color.hex)}
                            placement="right"
                        />
                    </div>
                </div>
                <div className="text-white text-left mt-3">Field background</div>
                <div className="row">
                    <div className="col text-left">
                        <InputColor
                            initialValue={data.fieldBgColor?data.fieldBgColor:"#ffffff"}
                            onChange={(color)=>setValue('fieldBgColor',color.hex)}
                            placement="right"
                        />
                    </div>
                </div>
                <div className="text-white text-left mt-3">Text color</div>
                <div className="row">
                    <div className="col text-left">
                        <InputColor
                            initialValue={data.textColor?data.textColor:"#000000"}
                            onChange={(color)=>setValue('textColor',color.hex)}
                            placement="right"
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 text-left">
                        <span className="text-white">Field border radius</span>
                    </div>
                    <div className="col-12 text-center">
                        <Slider
                            styles={{
                                active: {
                                    backgroundColor: '#86d3ff'
                                },
                                thumb: {
                                    width: 20,
                                    height: 20,
                                    backgroundColor: '#2693e6'
                                },
                                disabled: {
                                    opacity: 0.5
                                }
                            }}
                            xmax={20} xmin={0} xstep={1} axis="x" x={data.fieldRadius} onChange={({ x }) => setValue('fieldRadius',x)} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 text-left">
                        <span className="text-white">Search placeholder</span>
                    </div>
                    <div className="col-12">
                        <input type="text" className="form-control" value={data.placeholder} onChange={(e)=>setValue('placeholder',e.target.value)}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Search by title</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            checked={data.searchTitle}
                            onChange={(nextChecked)=>setValue('searchTitle',nextChecked)}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Search by page name</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            checked={data.searchPageName}
                            onChange={(nextChecked)=>setValue('searchPageName',nextChecked)}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Search by page description</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            checked={data.searchPageDescription}
                            onChange={(nextChecked)=>setValue('searchPageDescription',nextChecked)}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Search by subtitle</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            checked={data.searchSubtitle}
                            onChange={(nextChecked)=>setValue('searchSubtitle',nextChecked)}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Search by text</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            checked={data.searchText}
                            onChange={(nextChecked)=>setValue('searchText',nextChecked)}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Search by image name</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            checked={data.searchImageName}
                            onChange={(nextChecked)=>setValue('searchImageName',nextChecked)}
                        />
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default Pagesearch
