import React,{useState,useEffect} from "react";
import API from "../../utils/API"
import {toastr} from "react-redux-toastr";

const Note = ({userId}) => {
    const [data,setData] = useState(null)

    const [note,setNote] = useState(null)

    const loadNotes = () => {
        API.get('/admin/users/notes/'+userId).then((response) => {
            setData(response.data.data)
        });
    }

    const addNote = () => {
        API.post('/admin/users/notes/'+userId,{note:note}).then((response) => {
            setNote(null);
            loadNotes();
            toastr.success('Success', 'Note was successfully added');
        });
    }

    useEffect(() => {
        loadNotes();
    }, [userId])

    return (
        <div className="mt-5">
            <strong>Notes</strong>
            {data &&
                <>
                    {data.map((dataItem,index) => {
                        return <div key={index} className="m-3 p-3 border-dark border"><strong>{dataItem.dateadd}</strong><div className="mt-2">{dataItem.note}</div></div>
                    })}
                </>
            }
            <div className="row mt-2 p-3">
                <div className="col-12">
                    <input type="text" className="form-control" onChange={(e)=>setNote(e.target.value)} value={note}/>
                </div>
                <div className="col-12 text-center mt-2">
                    <button className="btn btn-primary" onClick={()=>addNote()}>Save</button>
                </div>
            </div>
        </div>
    )
}

export default Note;
