import React,{useState,useEffect} from "react";
import API from "../../utils/API";
import {AvField} from "availity-reactstrap-validation";

const Blockcategory = ({name,value,onChange,required}) => {
    const [categories,setCategories] = useState(null)

    useEffect(() => {
        API.get('/admin/category/all').then((response) => {
            setCategories(response.data.data)
        });
    }, [])

    return (
        <AvField
            type="select"
            name={name}
            value={value}
            onChange={onChange}
            required={required}
        >
            <option value="">Select...</option>
            {categories &&
            categories.map((category, index) => (
                <option key={index} selected={value === category.id} value={category.id}>{category.title}</option>
            ))
            }
        </AvField>
    )

}

export default Blockcategory;
