import React from "react"

const SubmissionData = ({submission}) => {
    return (
        <ul className="list-unstyled mb-0">
            <li className="mt-1">App title: <input className="form-control" readOnly={true} value={submission.appsubmit.data.name}/></li>
            <li className="mt-1">App short description: <textarea readOnly={true} className="form-control">{submission.appsubmit.data.shortdescription}</textarea></li>
            <li className="mt-1">App long description: <textarea readOnly={true} className="form-control">{submission.appsubmit.data.longdescription}</textarea></li>
            <li className="mt-1">URL: <input className="form-control" readOnly={true} value={submission.appsubmit.data.website}/></li>
            <li className="mt-1">Email: <input className="form-control" readOnly={true} value={submission.appsubmit.data.email}/></li>
        </ul>
    )
}

export default SubmissionData
