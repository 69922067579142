import React,{useEffect,useState,useRef} from "react"
import bgImage from "../../../../assets/blocks/header1/bg.jpeg"
import InlineEditableText from "../InlineEditableText"
import Switch from "react-switch"
import {Input} from "reactstrap"
import API from "../../../../utils/API"
import InputColor from 'react-input-color'
import Slider from 'react-input-slider'

const Ageverification = (props) => {

    const [data, setData] = useState((props.data)?props.data:props.defaultdata?props.defaultdata:{titleactive:true,subtitleactive:true,textactive:true,bgImage:bgImage,bgType:'image',minimumAge:21,verificationType:'buttons'})

    const [showOption, setShowOptions] = useState(false)

    const ref = useRef();

    const handleClickOutside = e => {
        if (ref && ref.current && !ref.current.contains(e.target)) {
            setShowOptions(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    },[]);

    const setValue = (name,value) => {
        let newData = {
            ...data,
            [name]: value
        }

        setData(newData)
        props.setValue(newData)
    }

    let image = {
        backgroundRepeat: "no-repeat",
        backgroundPosition : "center",
        backgroundSize: "cover",
        backgroundImage: (!data.bgType || data.bgType === 'image')?`url(${data.bgImage?data.bgImage:bgImage})`:'',
        backgroundColor: (data.bgType && data.bgType === 'color')?(data.bgColor?data.bgColor:'#000000'):'',
        position:"relative",
        border:(props.isResult)?"dashed 1px #64afb9":"0",
        paddingTop: (data.paddingTop || data.paddingTop === 0)?data.paddingTop:0,
        paddingBottom: (data.paddingBottom || data.paddingBottom === 0)?data.paddingBottom:0,
    }

    let overlay = {
        background: data.overlayColor?data.overlayColor:"#000000",
        opacity: (data.opacity || data.opacity === 0)?data.opacity:0,
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0
    }

    const handleFileSelect = (event) => {
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = (e) => {
            API.post('/imageadmin',{image:e.target.result}).then((response) => {
                setValue('bgImage',response.data.data.url)
            });
        }
    }

    return (
        <div style={{position:"relative"}} onClick={(e)=>{if (e.target.tagName.toLowerCase() === 'div'){
            if (props.isResult) {
                setShowOptions(true)
            }
        }}}>
            <div style={image} className="d-flex">
                <div style={overlay}/>
                <div className="w-100 text-white bTitleText pt-1 pb-1">
                    {data.titleactive &&
                        <div className="row justify-content-center">
                            <div className="col-auto">
                                <InlineEditableText isResult={props.isResult} name="title"
                                                    setText={setValue}>{data.title ? data.title : '<h1><span style="color: rgb(255, 255, 255);">Title</span></h1>'}</InlineEditableText>
                            </div>
                        </div>
                    }
                    {data.subtitleactive &&
                        <div className="row justify-content-center">
                            <div className="col-auto">
                                <InlineEditableText isResult={props.isResult} name="subtitle"
                                                    setText={setValue}>{data.subtitle ? data.subtitle : '<h2><span style="color: rgb(255, 255, 255);">Sub title</span></h2>'}</InlineEditableText>
                            </div>
                        </div>
                    }
                    {data.textactive &&
                        <div className="row justify-content-center">
                            <div className="col-auto">
                                <InlineEditableText isResult={props.isResult} name="text" setText={setValue}>{data.text?data.text:'Text'}</InlineEditableText>
                            </div>
                        </div>
                    }
                    {data.verificationType == 'buttons' &&
                        <div className="row justify-content-center">
                            <div className="col-auto">
                                <button className={"btn btn-primary btn-sm m-2"}><InlineEditableText isResult={props.isResult} name="buttonYes" setText={setValue}>{data.buttonYes?data.buttonYes:`Yes, I'm over ${data.minimumAge} - ENTER`}</InlineEditableText></button>
                            </div>
                            <div className="col-auto">
                                <button className={"btn btn-primary btn-sm m-2"}><InlineEditableText isResult={props.isResult} name="buttonNo" setText={setValue}>{data.buttonNo?data.buttonNo:`No, I'm under ${data.minimumAge} - LEAVE`}</InlineEditableText></button>
                            </div>
                        </div>
                    }
                    {data.verificationType == 'dobinput' &&
                        <>
                            <div className="row justify-content-center">
                                <div className="col-auto">
                                    <input type="text" maxLength={2} className="form-control" placeholder="DD" style={{width:"50px"}}/>
                                </div>
                                <div className="col-auto">
                                    <input type="text" maxLength={2} className="form-control" placeholder="MM" style={{width:"50px"}}/>
                                </div>
                                <div className="col-auto">
                                    <input type="text" maxLength={4} className="form-control" placeholder="YYYY" style={{width:"70px"}}/>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-auto">
                                    <button className={"btn btn-primary btn-sm m-2"}><InlineEditableText isResult={props.isResult} name="buttonEnter" setText={setValue}>{data.buttonEnter?data.buttonEnter:`ENTER`}</InlineEditableText></button>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
            {showOption &&
                <div ref={ref} className="pb-5" style={{position:"absolute",left:"101%",backgroundColor:"black",top:0,zIndex:"1000",borderRadius:"5px",width:"250px",textAlign:"center",padding:"10px"}}>
                    <div className="row">
                        <div className="col">
                            <span className="text-white">Settings</span>
                        </div>
                    </div>
                    <span className="text-white">Age verification type</span>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Buttons</span>
                        </div>
                        <div className="col-6 text-right">
                            <Input type="radio" style={{width:25,height:25}} name="verificationType" checked={data.verificationType==='buttons'} onChange={() => setValue('verificationType','buttons')}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Date of birth input</span>
                        </div>
                        <div className="col-6 text-right">
                            <Input type="radio" style={{width:25,height:25}} name="verificationType" checked={data.verificationType==='dobinput'} onChange={() => setValue('verificationType','dobinput')} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 text-left">
                            <span className="text-white">Minimum age: {data.minimumAge}</span>
                        </div>
                        <div className="col-12 text-center">
                            <Slider
                                styles={{
                                    active: {
                                        backgroundColor: '#86d3ff'
                                    },
                                    thumb: {
                                        width: 20,
                                        height: 20,
                                        backgroundColor: '#2693e6'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                                xmax={21} xmin={3} xstep={1} axis="x" x={(data.minimumAge || data.minimumAge === 0)?data.minimumAge:0} onChange={({ x }) => setValue('minimumAge',x)} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Title</span>
                        </div>
                        <div className="col-6 text-right">
                            <Switch
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                checked={data.titleactive}
                                onChange={(nextChecked)=>setValue('titleactive',nextChecked)}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Subtitle</span>
                        </div>
                        <div className="col-6 text-right">
                            <Switch onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={30}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                    checked={data.subtitleactive} onChange={(nextChecked)=>setValue('subtitleactive',nextChecked)}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Text</span>
                        </div>
                        <div className="col-6 text-right">
                            <Switch onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={30}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                    checked={data.textactive} onChange={(nextChecked)=>setValue('textactive',nextChecked)}/>
                        </div>
                    </div>
                    <span className="text-white">Padding</span>
                    <div className="row mt-3">
                        <div className="col-12 text-left">
                            <span className="text-white">Top</span>
                        </div>
                        <div className="col-12 text-center">
                            <Slider
                                styles={{
                                    active: {
                                        backgroundColor: '#86d3ff'
                                    },
                                    thumb: {
                                        width: 20,
                                        height: 20,
                                        backgroundColor: '#2693e6'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                                xmax={50} xmin={0} xstep={1} axis="x" x={(data.paddingTop || data.paddingTop === 0)?data.paddingTop:0} onChange={({ x }) => setValue('paddingTop',x)} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 text-left">
                            <span className="text-white">Bottom</span>
                        </div>
                        <div className="col-12 text-center">
                            <Slider
                                styles={{
                                    active: {
                                        backgroundColor: '#86d3ff'
                                    },
                                    thumb: {
                                        width: 20,
                                        height: 20,
                                        backgroundColor: '#2693e6'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                                xmax={50} xmin={0} xstep={1} axis="x" x={(data.paddingBottom || data.paddingBottom === 0)?data.paddingBottom:0} onChange={({ x }) => setValue('paddingBottom',x)} />
                        </div>
                    </div>
                    <span className="text-white">Background</span>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Image</span>
                        </div>
                        <div className="col-6 text-right">
                            <Input type="radio" style={{width:25,height:25}} name="bgtype" checked={data.bgType==='image'} onChange={() => setValue('bgType','image')}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 text-left">
                            <span className="text-white">Color</span>
                        </div>
                        <div className="col-6 text-right">
                            <Input type="radio" style={{width:25,height:25}} name="bgtype" checked={data.bgType==='color'} onChange={() => setValue('bgType','color')} />
                        </div>
                    </div>
                    {data.bgType === 'image' &&
                        <>
                            <div className="row mt-3">
                                <div className="col text-left">
                                    {data.bgImage &&
                                        <img src={data.bgImage} className="img-fluid"/>
                                    }
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-6 text-left">
                                    <input type="file" onChange={handleFileSelect}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 text-left">
                                    <span className="text-white">Overlay</span>
                                </div>
                                <div className="col-6 text-right">
                                    <InputColor
                                        initialValue={data.overlayColor?data.overlayColor:"#000000"}
                                        onChange={(color)=>setValue('overlayColor',color.hex)}
                                        placement="right"
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 text-left">
                                    <span className="text-white">Opacity</span>
                                </div>
                                <div className="col-12 text-center">
                                    <Slider
                                        styles={{
                                            active: {
                                                backgroundColor: '#86d3ff'
                                            },
                                            thumb: {
                                                width: 20,
                                                height: 20,
                                                backgroundColor: '#2693e6'
                                            },
                                            disabled: {
                                                opacity: 0.5
                                            }
                                        }}
                                        xmax={1} xmin={0} xstep={0.1} axis="x" x={(data.opacity || data.opacity === 0)?data.opacity:0} onChange={({ x }) => setValue('opacity',x)} />
                                </div>
                            </div>
                        </>
                    }
                    {data.bgType === 'color' &&
                        <>
                            <div className="row mt-3">
                                <div className="col text-left">
                                    <InputColor
                                        initialValue={data.bgColor?data.bgColor:"#000000"}
                                        onChange={(color)=>setValue('bgColor',color.hex)}
                                        placement="right"
                                    />
                                </div>
                            </div>

                        </>
                    }
                </div>
            }
        </div>
    )
}

export default Ageverification
