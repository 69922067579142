import React from "react";
import {Badge} from 'reactstrap';

const AdminStatus = ({ status}) => {
    if (status == "1") {
        return <Badge color="success" className="mr-1 mb-1">
            Active
        </Badge>
    }else if (status == "0") {
        return <Badge color="warning" className="mr-1 mb-1">
            Not active
        </Badge>
    }else {
        return <></>
    }
};

export default AdminStatus;
