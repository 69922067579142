import React,{useState} from "react"

const Coffee = ({items,categories}) => {
    const [currentItems, setCurrentItems] = useState(items)
    const [currentCategories, setCurrentCategories] = useState(categories)

    return (
        <div>
            {currentItems.length > 0 &&
                <div className="text-black-50">
                    <div className="row m-2">
                        {
                            currentItems.map((inCategoryItem,inCategoryItemsKey)=>{
                                if (!inCategoryItem.category) {
                                    return (
                                        <div className="col-sm-12 text-center p-1" key={inCategoryItemsKey}>
                                            <div className="barSection">
                                                <strong> {inCategoryItem.name} </strong>
                                                <span className="barSpan">..............................................................................................................................................................</span>
                                                <h3>  ${inCategoryItem.price} </h3>
                                            </div>
                                        </div>
                                    )
                                }else {
                                    return <></>
                                }
                            })
                        }
                    </div>
                    {currentCategories.map((category,categoryIndex)=>{
                        let inCategoryItems = [],i,n = currentItems.length
                        for (i = 0; i < n; i++) {
                            if (currentItems[i].category === category.name && category.name) {
                                inCategoryItems.push(currentItems[i])
                            }
                        }
                        if (inCategoryItems.length > 0) {
                            return <div key={categoryIndex}>
                                <div className="text-center mt-2"><span className="text-danger font-size-lg font-weight-bolder">{category.name}</span></div>
                                <div className="row m-2">
                                    {
                                        inCategoryItems.map((inCategoryItem,inCategoryItemsKey)=>{
                                            return (
                                                <div className="col-sm-12 text-center p-1" key={inCategoryItemsKey}>
                                                    <div className="barSection">
                                                        <strong> {inCategoryItem.name} </strong>
                                                        <span className="barSpan">..............................................................................................................................................................</span>
                                                        <h3>  ${inCategoryItem.price} </h3>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }else {
                            return <></>
                        }
                    })}
                </div>
            }
        </div>
    )
}

export default Coffee
