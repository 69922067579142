import React,{useState,useEffect} from "react";
import API from "../../utils/API";
import {CardBody, Table} from "reactstrap";
import ListStat from "../../components/ListStat";
import Pagination from "../../components/Pagination";
import Loader from "../../components/Loader";

const Logs = ({user,type}) => {

    const [logs,setLogs] = useState(null)
    const [filtration,setFiltration] = useState({page:1,perPage:100})
    const [data,setData] = useState(null)
    const [loading, setLoading] = useState(true)

    const changeData = (name,value) => {
        setFiltration({ ...filtration, [name]: value })
    }

    useEffect(()=>{
        setLoading(true)
        API.post('/admin/users/log',{user,type,...filtration}).then((response) => {
            setLogs(response.data.data.data)
            setLoading(false)
            setData(response.data.data)
        });
    },[user,type,filtration])

    return (
        <>
            {logs &&
                <>
                    {loading &&
                        <Loader/>
                    }
                    <div className="table-responsive">
                        {(logs && logs.length > 0) &&
                            <>
                        <ListStat data={data}/>
                        <Table>
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Type</th>
                                <th>Log</th>
                                <th>App</th>
                            </tr>
                            </thead>
                            <tbody>
                            {logs.map((log,key)=>{
                                return (
                                    <tr key={key}>
                                        <td>{log.userlog.date}</td>
                                        <td>{log.userlog.logtype}</td>
                                        <td>{log.userlog.content}</td>
                                        <td>{log.app.title}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                        {(data?.page > 0 && data?.pages > 0) &&
                            <Pagination
                                onChange={changeData}
                                name={"page"}
                                page={data.page}
                                totalPages={data.pages}
                                loading={loading}/>
                        }
                            </>
                        }
                    </div>
                </>
            }
        </>
    )
}

export default Logs
