import React, {useRef, useState, useEffect} from "react";
import ReactQuill, {Quill} from 'react-quill';
import parse from 'html-react-parser';
import { v4 as uuidv4 } from 'uuid';

const InlineEditableText = (props) => {
    const [content,setContent] = useState(props.children)

    const [ready,setReady] = useState(false)

    const quillRef = useRef()

    const blockId = uuidv4()

    const Quill = ReactQuill.Quill;

    let Size = Quill.import('attributors/style/size');
    Size.whitelist = ['10px', '11px', '12px','13px','14px','15px','16px','17px','18px','19px','20px','21px','22px','23px','24px','25px','26px','27px','28px','29px','30px'];
    Quill.register(Size, true);

    let Font = Quill.import("attributors/class/font");
    Font.whitelist = ["Assistant", "BebasNeue", "Caveat","Cinzel","DancingScript","IndieFlower", "JosefinSans", "Lato", "Lobster", "Lora" ,"Merriweather","Montserrat","Nunito","Oswald","OpenSans", "Poppins","Raleway","Roboto", "Redressed","Rubik","Shizuru","Ubuntu"];
    Quill.register(Font, true);

    const setText = (text) => {
        if (ready) {
            setContent(text)
            props.setText(props.name,text)
        }
    }

    const modules = {
        toolbar: {
            container: [
                [{ size: ['','10px', '12px','14px','16px','18px','20px','22px','24px','26px','28px','30px'] },{ font: ["","Assistant", "BebasNeue", "Caveat", "Cinzel","DancingScript", "IndieFlower", "JosefinSans", "Lato", "Lobster", "Lora" ,"Merriweather","Montserrat","Nunito","OpenSans", "Oswald","Poppins","Raleway","Roboto", "Redressed","Rubik","Shizuru","Ubuntu"] },{ 'align': ['','right','center','justify']},{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }],
                ['bold', 'italic', 'underline','link']
            ]
        }
    }

    const formats = [
        'size','font','list',
        'bold', 'italic', 'underline', 'link','color','background','color','align'
    ]

    return (
        <>
            {props.isResult
                ?
                <ReactQuill
                    ref={quillRef}
                    id={blockId}
                    theme="bubble"
                    defaultValue={content}
                    formats={formats}
                    onChange={setText}
                    modules={modules}
                    onFocus={()=>{setReady(true)}}
                />
                :
                <div className="blockText" style={{zIndex:"100 !important",overflow:"hidden"}}>
                    {parse(content)}
                </div>
            }
        </>
    )
}

export default InlineEditableText
