import React,{useEffect,useState,useRef} from "react"
import {Input} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

const FormElement = ({data,setValue,deleteFormElement}) => {
    const [currentData, setCurrentData] = useState(data)

    const changeValue = (name, value) => {
        let currentDataValues = currentData
        currentDataValues[name] = value
        setCurrentData(currentDataValues)
        setValue(currentDataValues.id,name,value)
    }

    return (
        <div>
            <hr style={{backgroundColor:"white",height:"1px",width:"100%"}}/>
            <div className="row">
                <div className="col text-right">
                    <FontAwesomeIcon icon={faTrash} color={"white"} className="cursor-pointer" onClick={(e)=>{deleteFormElement(currentData.id)}}/>
                </div>
            </div>
            <div className="row">
                <div className="col-6 text-left">
                    <span className="text-white">Input</span>
                </div>
                <div className="col-6 text-right">
                    <Input type="radio" style={{width:25,height:25}} name={currentData.id+"type"} checked={currentData.type==='input'} onChange={() => changeValue('type','input')}/>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-6 text-left">
                    <span className="text-white">Text</span>
                </div>
                <div className="col-6 text-right">
                    <Input type="radio" style={{width:25,height:25}} name={currentData.id+"type"} checked={currentData.type==='text'} onChange={() => changeValue('type','text')} />
                </div>
            </div>
            <div className="mt-3">
                <div className="text-white text-left mb-1">Name</div>
                <input placeholder="Name" type="text" value={currentData.name} className="form-control" onChange={(e)=>changeValue('name',e.target.value)}/>
            </div>
            <div className="mt-3">
                <div className="text-white text-left mb-1">Placeholder</div>
                <input placeholder="Placeholder" type="text" value={currentData.placeholder} className="form-control" onChange={(e)=>changeValue('placeholder',e.target.value)}/>
            </div>
        </div>
    )
}

export default FormElement
