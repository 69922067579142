import React,{useState,useEffect} from "react"
import API from "../../utils/API";
import {Badge} from "reactstrap";
import PlatformUrl from "./PlatformUrl";
import PublishStatus from "./PublishStatus";

const Platforms = ({submissionId}) => {
    const [platforms,setPlatforms] = useState(null);

    useEffect(() => {
        API.post('/admin/appsubmit/publishlist/'+submissionId).then((response) => {
            setPlatforms(response.data.data)
        });
    }, [submissionId])

    const Platform = ({platform}) => {
        return (
            <>
                {platform &&
                    <div>
                        <h4>{platform.appplatform.title}</h4>
                        <PublishStatus publish={platform.apppublish}/>
                        <div className="mt-2"><strong>URL</strong></div>
                        <PlatformUrl publishId={platform.apppublish.id}/>
                    </div>
                }
            </>
        )
    }

    return (
        <div>
            {platforms &&
                <div className="row">
                    <div className={`col${platforms.length===1?'-12':'-6'}`}>
                        <Platform platform={platforms[0]}/>
                    </div>
                    <div className={`col${platforms.length===1?'-12':'-6'}`}>
                        <Platform platform={platforms[1]}/>
                    </div>
                </div>
            }
        </div>
    )
}

export default Platforms
