import React,{useEffect,useState,useRef} from "react"
import InlineEditableText from "../InlineEditableText"
import Switch from "react-switch"
import InputColor from 'react-input-color'
import Slider from 'react-input-slider'
import ReactPlayer from 'react-player'

const Video = (props) => {

    const [showOption, setShowOptions] = useState(false)
    const [data, setData] = useState((props.data)?props.data:props.defaultdata?props.defaultdata:{titleactive:true,subtitleactive:true,textactive:true,video:'https://www.youtube.com/watch?v=DFq9oe1PInA'})

    const [clickedOutside, setClickedOutside] = useState(false);

    const ref = useRef();

    const handleClickOutside = e => {
        if (ref && ref.current && !ref.current.contains(e.target)) {
            setClickedOutside(true)
            setShowOptions(false)
        }
    };

    const handleClickInside = () => {
        setClickedOutside(false)
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    },[]);

    const setValue = (name,value) => {
        let newData = {
            ...data,
            [name]: value
        }

        setData(newData)
        props.setValue(newData)
    }

    let main = {
        position:"relative",
        border:(props.isResult)?"dashed 1px #64afb9":"0",
        paddingTop: (data.paddingTop || data.paddingTop === 0)?data.paddingTop:0,
        paddingBottom: (data.paddingBottom || data.paddingBottom === 0)?data.paddingBottom:0,
        backgroundColor: data.bgColor?data.bgColor:'#000000'
    }

    let overlay = {
        background: data.overlayColor?data.overlayColor:"#000000",
        opacity: (data.opacity || data.opacity === 0)?data.opacity:0,
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0
    }

    return (
        <div style={{position:"relative"}} onClick={(e)=>{if (e.target.tagName.toLowerCase() === 'div'){
            if (props.isResult) {
                setShowOptions(true)
            }
        }}}>
            <div style={main} className="d-flex">
                <div style={overlay}/>
                <div className="w-100 text-white bTitleText pt-1 pb-1">
                    {data.titleactive &&
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <InlineEditableText isResult={props.isResult} name="title"
                                                setText={setValue}>{data.title ? data.title : '<h1 class="ql-align-center"><span style="color: rgb(255, 255, 255);">View in action</span></h1>'}</InlineEditableText>
                        </div>
                    </div>
                    }
                    {data.subtitleactive &&
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <InlineEditableText isResult={props.isResult} name="subtitle"
                                                setText={setValue}>{data.subtitle ? data.subtitle : '<p class="ql-align-center"><span style="color: rgb(255, 255, 255);">Sub title</span></p>'}</InlineEditableText>
                        </div>
                    </div>
                    }
                    {data.video &&
                        <div className='player-wrapper'>
                            <ReactPlayer
                                className='react-player'
                                light
                                playing={false}
                                controls={false}
                                width='100%'
                                height='100%'
                                url={data.video}
                            />
                        </div>
                    }
                    {data.textactive &&
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <InlineEditableText isResult={props.isResult} name="text" setText={setValue}>{data.text?data.text:'<p class="ql-align-center"><span style="color: rgb(255, 255, 255);">Text</span></p>'}</InlineEditableText>
                        </div>
                    </div>
                    }
                </div>
            </div>
            {showOption &&
            <div ref={ref} className="pb-5" style={{position:"absolute",left:"101%",backgroundColor:"black",top:0,zIndex:"1000",borderRadius:"5px",width:"250px",textAlign:"center",padding:"10px"}}>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Title</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            checked={data.titleactive}
                            onChange={(nextChecked)=>setValue('titleactive',nextChecked)}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Subtitle</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                checked={data.subtitleactive} onChange={(nextChecked)=>setValue('subtitleactive',nextChecked)}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6 text-left">
                        <span className="text-white">Text</span>
                    </div>
                    <div className="col-6 text-right">
                        <Switch onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                                className="react-switch"
                                checked={data.textactive} onChange={(nextChecked)=>setValue('textactive',nextChecked)}/>
                    </div>
                </div>
                <span className="text-white">Padding</span>
                <div className="row mt-3">
                    <div className="col-12 text-left">
                        <span className="text-white">Top</span>
                    </div>
                    <div className="col-12 text-center">
                        <Slider
                            styles={{
                                active: {
                                    backgroundColor: '#86d3ff'
                                },
                                thumb: {
                                    width: 20,
                                    height: 20,
                                    backgroundColor: '#2693e6'
                                },
                                disabled: {
                                    opacity: 0.5
                                }
                            }}
                            xmax={50} xmin={0} xstep={1} axis="x" x={(data.paddingTop || data.paddingTop === 0)?data.paddingTop:0} onChange={({ x }) => setValue('paddingTop',x)} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 text-left">
                        <span className="text-white">Bottom</span>
                    </div>
                    <div className="col-12 text-center">
                        <Slider
                            styles={{
                                active: {
                                    backgroundColor: '#86d3ff'
                                },
                                thumb: {
                                    width: 20,
                                    height: 20,
                                    backgroundColor: '#2693e6'
                                },
                                disabled: {
                                    opacity: 0.5
                                }
                            }}
                            xmax={50} xmin={0} xstep={1} axis="x" x={(data.paddingBottom || data.paddingBottom === 0)?data.paddingBottom:0} onChange={({ x }) => setValue('paddingBottom',x)} />
                    </div>
                </div>
                <span className="text-white">Background</span>
                <div className="row mt-3">
                    <div className="col text-left">
                        <InputColor
                            initialValue={data.bgColor?data.bgColor:"#000000"}
                            onChange={(color)=>setValue('bgColor',color.hex)}
                            placement="right"
                        />
                    </div>
                </div>
                <span className="text-white">Video</span>
                <div className="row mt-3">
                    <div className="col text-left">
                        <input className="form-control" type="text" value={data.video} onChange={(e)=>{
                            setValue('video',e.target.value)
                        }}/>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default Video
